import { debounce } from 'lodash-es';

const root = document.querySelector(':root');

function getHeaderHeight() {
  const siteNav = document.getElementById('site-nav');
  // if site nav is not found, return 0
  if (!siteNav) return 0;
  // return site nav height
  return `${siteNav.offsetHeight}px`;
}

function getWindowHeight() {
  return `${window.innerHeight}px`;
}

function debouncedResizeHandler() {
  root.style.setProperty('--site-nav-height', getHeaderHeight());
}

root.style.setProperty('--site-nav-height', getHeaderHeight());
root.style.setProperty('--vh-fix', getWindowHeight());

window.addEventListener('resize', debounce(debouncedResizeHandler, 100));
