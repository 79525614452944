export default class FormValidationFieldset {
  constructor(fieldsetEl) {
    this.dom = {
      fieldsetEl,
      links: fieldsetEl.hasAttribute('id') ? [...document.querySelectorAll(`a[href="#${fieldsetEl.id}"]`)] : [],
      message: fieldsetEl.querySelector('.js-message'),
    };
  }

  /**
   * @param message {string|null}
   */
  setInvalid(message = null) {
    this.dom.fieldsetEl.classList.add('is-invalid');

    // Link references
    this.dom.links.forEach((link) => {
      link.classList.add('is-invalid');
    });

    if (message) {
      this.setMessage(message);
    } else {
      this.resetMessage();
    }
  }

  setValid() {
    this.dom.fieldsetEl.classList.remove('is-invalid');

    // Link references
    this.dom.links.forEach((link) => {
      link.classList.remove('is-invalid');
    });

    this.resetMessage();
  }

  setMessage(message) {
    if (!this.dom.message) return;

    // apply text
    this.setMessageText(message);

    // show message
    this.dom.message.classList.remove('d-none');
  }

  resetMessage() {
    // if we have no message element, exit fn
    if (!this.dom.message) return;

    // hide message
    this.dom.message.classList.add('d-none');

    // reset text
    this.setMessageText('');
  }

  setMessageText(text) {
    this.dom.message.querySelector('strong').textContent = text;
  }
}
