// components
import FormValidation from '@/js/components/forms/formValidation';

class Newsletter {
  constructor() {
    this.dom = {
      formSubscriber: document.getElementById('form-subscriber'),
    };

    this.components = {
      subscriberFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formSubscriber) {
      this.components.subscriberFormValidation = new FormValidation(this.dom.formSubscriber);
    }
  }
}

if (document.getElementById('page-newsletter')) {
  // eslint-disable-next-line no-new
  new Newsletter();
}
