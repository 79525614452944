import FilterForm from '@/js/components/forms/filterForm';

class BrokerOverview {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
    };

    this.components = {
      filterForm: undefined,
    };

    this.mount();
  }

  mount() {
    if (this.dom.filterForm) {
      this.components.filterForm = new FilterForm(this.dom.filterForm);
    }
  }
}

if (document.getElementById('page-brokers-overview')) {
  // eslint-disable-next-line no-new
  new BrokerOverview();
}
