import EstateFilterForm from '@/js/components/forms/estateFilterForm';
import ActionElement from '@/js/components/forms/actionElement';

class AccountPreferences {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
      filterBadges: document.querySelectorAll('.filter-badge'),
    };

    this.components = {
      filterForm: null,
      resetButtons: [],
    };

    this.events = {
      onResetSuccessful: this.onResetSuccessful.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.filterForm) {
      this.components.filterForm = new EstateFilterForm(this.dom.filterForm);
      this.components.filterForm.props.submitOnChange = false;

      this.components.resetButtons = [...document.querySelectorAll('button[data-request-action="reset"]')]
        .map((button) => new ActionElement({ element: button }));

      this.components.resetButtons.forEach((button) => button.dom.element.addEventListener('success', this.events.onResetSuccessful));
    }
  }

  onResetSuccessful() {
    this.components.filterForm.reset();
    this.components.filterForm.submit();
    this.dom.filterBadges.forEach((badge) => {
      const el = badge;
      el.classList.add('d-none');
      el.textContent = '';
    });

    console.log('clear numbers');
  }
}

if (document.getElementById('page-account-preferences')) {
  // eslint-disable-next-line no-new
  new AccountPreferences();
}
