import { ScrollSpy, Toast } from 'bootstrap';
import EstateFormValidation from '@/js/components/forms/estateFormValidation';
import Dropzone from '@/js/components/dropzone';

class AccountEstateEdit {
  constructor() {
    this.dom = {
      formEstate: document.getElementById('form-estate'),
      selectLanguage: document.getElementById('estate-texts-languages'),
      dropzoneEl: document.getElementById('estate-photos-dropzone'),
      toastMaxExceedError: document.getElementById('toast-max-exceeded-error'),
      toastFileDisallowed: document.getElementById('toast-file-disallowed'),
      textAreas: document.getElementsByClassName('auto-size'),
    };

    this.components = {
      estateFormValidation: null,
      scrollSpy: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEstate) {
      this.components.estateFormValidation = new EstateFormValidation(this.dom.formEstate);
    }

    this.checkScrollHeights();

    this.dom.selectLanguage.addEventListener('change', (e) => {
      document.querySelectorAll('.collapse-language.show')[0].classList.remove('show');
      document.getElementById(`estate-texts-${e.currentTarget.value}`).classList.add('show');
      this.checkScrollHeights();
    });

    if (this.dom.dropzoneEl) {
      this.components.dropzone = new Dropzone({ container: this.dom.dropzoneEl, url: window.location.href });
      // Catch the errors of the dropzone
      this.components.dropzone.components.dropzone.on('error', (file, response) => {
        // Show toast notifications
        if (this.dom.toastFileDisallowed && response === this.components.dropzone.data.options.dictInvalidFileType) {
          Toast.getOrCreateInstance(this.dom.toastFileDisallowed).show();
        }

        // Process the error as an alert
        this.components.estateFormValidation.components.fieldsets.forEach((fieldset) => {
          if (fieldset.dom.fieldsetEl.name === 'Photos') {
            // Add the error message to the photos fieldset
            fieldset.setInvalid(Object.values(response?.errors ?? {}).join(', '));
          }
        });
      });

      if (this.dom.toastMaxExceedError) {
        this.components.dropzone.components.dropzone.on('maxfilesexceeded', () => {
          Toast.getOrCreateInstance(this.dom.toastMaxExceedError).show();
        });
      }
    }

    this.components.scrollSpy = new ScrollSpy(document.body, {
      target: '#aside-nav',
      offset: 80,
    });
  }

  checkScrollHeights() {
    Array.prototype.forEach.call(this.dom.textAreas, (el) => {
      if (el.scrollHeight > el.style.minHeight) {
        document.getElementById(el.id).style.height = `${el.scrollHeight + 10}px`;
      }
    });
  }
}

if (document.getElementById('page-account-estate-edit')) {
  // eslint-disable-next-line no-new
  new AccountEstateEdit();
}
