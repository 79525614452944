export default class FileDownloader {
  /**
   * @param url {string}
   * @param filename {string|null}
   */
  constructor({ url, filename = null }) {
    this.props = {
      url,
      filename,
    };
  }

  download() {
    // Creat a tag
    const a = document.createElement('a');
    a.href = this.props.url;
    a.download = this.props.filename ?? '';

    // Download
    a.click();
  }
}
