const requireAll = (r) => {
  r.keys().forEach(r);
};

/**
 * @param string {string}
 * @param logError {boolean}
 * @return {boolean}
 */
const validateJson = (string, logError = false) => {
  try {
    JSON.parse(string);
  } catch (e) {
    if (logError) {
      console.error(e);
    }
    return false;
  }
  return true;
};

export {
  requireAll,
  validateJson,
};
