import Tracker from '@/js/components/tracking/tracker';

[...document.querySelectorAll('[data-track]')]
  .forEach((element) => {
    try {
      const data = JSON.parse(element.dataset.track);

      // eslint-disable-next-line no-new
      new Tracker({ element, data });
    } catch (error) {
      console.error(error);
    }
  });
