import ActionHandler from '@/js/components/forms/actionHandler';
import DropdownFilter from '@/js/components/filters/dropdownFilter';
import InputFilter from '@/js/components/filters/InputFilter';

export default class FilterForm extends ActionHandler {
  /**
   * @param formEl {HTMLFormElement}
   */
  constructor(formEl) {
    super();

    this.dom = {
      formEl,
      loaderEl: document.getElementById('c-loader'),
    };

    this.components = {
      dropdownFilters: [...formEl.querySelectorAll('.filter-item')].map((field) => new DropdownFilter(field)),
      inputFilters: [...formEl.querySelectorAll('.filter-input')].map((field) => new InputFilter(field)),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
      action: formEl.querySelector('input[name="action"]')?.value ?? 'filter',
      type: null,
      submitOnChange: true,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

    this.components.dropdownFilters.forEach((filter) => {
      filter.dom.filterEl.addEventListener('change', (e) => this.submit(e.currentTarget));
    });

    this.components.inputFilters.forEach((filter) => {
      filter.dom.input.addEventListener('change', (e) => this.submit(e.currentTarget));
    });

    if (this.dom.loaderEl) {
      this.addLoader(this.dom.loaderEl);
    }
  }

  /**
   * @param e {Event}
   */
  onFormSubmit(e) {
    e.preventDefault();

    // Initialize form data
    const formData = new FormData(this.dom.formEl);

    this.components.dropdownFilters.forEach((filter) => {
      filter.dom.items
        .filter((item) => item.classList.contains('active'))
        .forEach((item) => {
          const { name, value } = item.dataset;

          if (!name || !value) {
            return;
          }

          formData.append(name, value);
        });
    });

    this.sendFormData({ url: this.props.url, formData, action: this.props.action });
  }

  /**
   * @param submitter {HTMLElement|null}
   */
  submit(submitter = null) {
    if (this.props.submitOnChange) {
      this.dom.formEl.dispatchEvent(new SubmitEvent('submit', { submitter, cancelable: true }));
    }
  }

  reset() {
    [...this.components.dropdownFilters, ...this.components.inputFilters]
      .forEach((filter) => filter.reset());
  }
}
