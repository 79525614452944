import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

export function event(data) {
  return axios.post(`${rootWithLanguage}tracking/event`, data);
}

export default {
  event,
};
