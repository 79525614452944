/* global google */

export default class InfoWindow {
  /**
   * create Google Maps InfoWindow
   * https://developers.google.com/maps/documentation/javascript/infowindows
   * @param options {Object}
   */
  constructor(options) {
    // https://developers.google.com/maps/documentation/javascript/reference/info-window
    const defaultOptions = {
      content: '',
      maxWidth: 320,
    };

    this.data = {
      infoWindow: null,
      options: { ...defaultOptions, ...options },
    };

    this.mount();
  }

  mount() {
    this.data.infoWindow = new google.maps.InfoWindow(this.data.options);
  }

  open(map, marker) {
    this.data.infoWindow.open(map, marker);
  }
}
