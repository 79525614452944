import axios from 'axios';
import ActionResponseData from '@/js/components/forms/actionResponseData';
import Loader from '@/js/components/loader';

export default class ActionHandler {
  constructor() {
    this.states = {
      idle: 'STATE_IDLE',
      awaitingRequest: 'STATE_AWAITING_REQUEST',
    };

    this.components = {
      loader: null,
    };

    this.currentState = this.states.idle;
  }

  /**
   * @param url {string}
   * @param data
   */
  send({ url, data = null }) {
    // Skip when is busy
    if (this.isBusy()) {
      return;
    }

    // disallow to send again
    this.currentState = this.states.awaitingRequest;

    // enable loader
    if (this.components.loader) {
      this.components.loader.start();
    }

    // send request
    axios.post(url, data)
      .then(this.onRequestSuccess.bind(this))
      .catch(this.onRequestError.bind(this))
      .finally(() => {
        if (this.components.loader) {
          this.components.loader.stop();
        }
      });
  }

  /**
   * form has been submitted
   * @param url {string}
   * @param formData {FormData}
   * @param action {string|null}
   */
  sendFormData({ url, formData, action = null }) {
    if (action && !formData.has('action')) {
      formData.append('action', action);
    }

    this.send({ url, data: formData });
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    // allow to send
    this.currentState = this.states.idle;

    (new ActionResponseData(response.data)).process();
  }

  /**
   * request has failed
   * @param error {Object}
   */
  onRequestError(error) {
    console.error(error);

    // allow to send
    this.currentState = this.states.idle;

    if (error.response) {
      (new ActionResponseData(error.response.data)).process();
    }
  }

  isBusy() {
    return this.currentState === this.states.awaitingRequest;
  }

  /**
   * @param loaderEl {HTMLElement}
   */
  addLoader(loaderEl) {
    this.components.loader = new Loader(loaderEl);
  }

  removeLoader() {
    this.components.loader = null;
  }
}
