import { Popover, Modal } from 'bootstrap';
import GoogleMaps from '@/js/components/googleMaps/map';
import FormValidation from '@/js/components/forms/formValidation';
import ToggleFavoriteButton from '@/js/components/buttons/toggleFavoriteButton';
import FileDownloader from '@/js/components/fileDownloader';

class EstateDetail {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('map'),
      popovers: document.querySelectorAll('[data-bs-toggle="popover"]'),
      formContact: document.getElementById('form-contact'),
      formLogin: document.getElementById('form-account-login'),
      formEstateReportCalculator: document.getElementById('form-estate-report-calculator'),
      formRequestEstateReport: document.getElementById('form-request-estate-report'),
      modalRequestEstateReport: document.getElementById('requestEstateReportModal'),

    };

    this.props = {
      mapMarkers: null,
      mapOptions: null,
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      contactFormValidation: null,
      loginFormValidation: null,
      estateReportCalculatorValidation: null,
      requestEstateReportValidation: null,
      requestEstateReportModal: null,
      toggleFavoriteButtons: [],
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.popovers) {
      this.initPopovers();
    }

    if (this.dom.formContact) {
      this.components.contactFormValidation = new FormValidation(this.dom.formContact);
    }

    if (this.dom.formLogin) {
      this.components.loginFormValidation = new FormValidation(this.dom.formLogin);
    }

    this.initEstateReportForms();

    this.components.toggleFavoriteButtons = [...document.querySelectorAll('button[data-request-action="toggle-favorite"]')]
      .map((button) => new ToggleFavoriteButton({ button }));
  }

  initEstateReportForms() {
    if (!this.dom.formEstateReportCalculator) {
      return;
    }

    this.components.estateReportCalculatorValidation = new FormValidation(this.dom.formEstateReportCalculator);
    this.dom.formEstateReportCalculator.addEventListener(this.components.estateReportCalculatorValidation.customEvents.success, (e) => {
      const { values, files } = e.detail;

      // If there are files, download them
      if (files && Object.keys(files).length !== 0) {
        Object.entries(files).forEach(([filename, url]) => {
          const downloader = new FileDownloader({ url, filename });
          downloader.download();
        });
        return;
      }

      // When no files are given, set the values on the form
      if (this.dom.formRequestEstateReport) {
        Object.entries(values).forEach(([name, value]) => {
          const existingInput = this.dom.formRequestEstateReport.querySelector(`input[name="${name}"]`);

          if (existingInput) {
            existingInput.value = String(value);
            return;
          }

          // add value
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = name;
          input.value = String(value);
          this.dom.formRequestEstateReport.prepend(input);
        });
      }

      // Show the modal with form
      if (this.components.requestEstateReportModal) {
        this.components.requestEstateReportModal.show();
      }
    });

    if (!this.dom.formRequestEstateReport && !this.dom.modalRequestEstateReport) {
      return;
    }

    this.components.requestEstateReportValidation = new FormValidation(this.dom.formRequestEstateReport);
    this.components.requestEstateReportModal = Modal.getOrCreateInstance(this.dom.modalRequestEstateReport);

    this.dom.formRequestEstateReport.addEventListener(this.components.requestEstateReportValidation.customEvents.success, () => {
      // Close modal on success
      if (this.components.requestEstateReportModal) {
        this.components.requestEstateReportModal.hide();
      }
    });
  }

  initMaps() {
    // parse props
    this.props.mapOptions = JSON.parse(this.dom.mapEl.dataset.options);
    this.props.mapMarkers = JSON.parse(this.dom.mapEl.dataset.markers);

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // add markers
    this.props.mapMarkers.forEach((marker) => {
      this.components.googleMaps.addMarker(marker);
    });
  }

  initPopovers() {
    // add markers
    this.dom.popovers.forEach((popover) => new Popover(popover));
  }
}

if (document.getElementById('page-estates-detail')) {
  // eslint-disable-next-line no-new
  new EstateDetail();
}
