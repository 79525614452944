import ActionElement from '@/js/components/forms/actionElement';

export default class ToggleFavoriteButton extends ActionElement {
  constructor({ button, url = null, action = null }) {
    super({ element: button, url, action });

    this.data = {
      activeClass: 'active',
    };
  }

  onElementClick(e) {
    super.onElementClick(e);
    this.dom.element.blur();
  }

  sendFormData({ url, formData, action = null }) {
    formData.append('value', this.isActive() ? '0' : '1');

    super.sendFormData({ url, formData, action });
  }

  onRequestSuccess(response) {
    super.onRequestSuccess(response);

    const isFavorite = response?.data?.isFavorite ?? false;

    if (isFavorite === true) {
      this.dom.element.classList.add(this.data.activeClass);
      return;
    }

    this.dom.element.classList.remove(this.data.activeClass);
  }

  isActive() {
    return this.dom.element.classList.contains(this.data.activeClass);
  }
}
