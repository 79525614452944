import { Dropdown } from 'bootstrap';

export default class DropdownFilter {
  /**
   * @param filterEl {HTMLButtonElement}
   */
  constructor(filterEl) {
    this.dom = {
      filterEl,
      items: [...filterEl.querySelectorAll('.dropdown-item')],
      button: filterEl.querySelector('button[data-bs-toggle="dropdown"]'),
      badge: filterEl.querySelector('.badge'),
      saveBtn: filterEl.querySelector('.filter-dropdown-save'),
    };

    this.components = {
      dropdown: Dropdown.getOrCreateInstance(this.dom.button),
    };

    this.props = {
      multiselectable: !!this.dom.filterEl.dataset.multiselect,
      originalText: this.dom.button.innerText,
    };

    this.data = {
      activeClass: 'active',
    };

    this.events = {
      onFilterItemClick: this.onFilterItemClick.bind(this),
      onSaveBtnClick: this.onSaveBtnClick.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.items.forEach((item) => {
      item.addEventListener('click', this.events.onFilterItemClick);
    });

    if (this.dom.saveBtn) {
      this.dom.saveBtn.addEventListener('click', this.events.onSaveBtnClick.bind(this));
    }

    this.updateActiveState();
  }

  /**
   * @param e {Event}
   */
  onFilterItemClick(e) {
    e.preventDefault();

    const { target } = e;

    // Change the active state on the item
    target.classList.toggle(this.data.activeClass);

    const siblings = this.dom.items.filter((el) => el !== target);

    if (!this.props.multiselectable) {
      // remove active state from siblings
      siblings.forEach((sibling) => {
        sibling.classList.remove(this.data.activeClass);
      });
    }

    this.updateActiveState();

    if (!this.dom.saveBtn) {
      this.submit();
    }
  }

  onSaveBtnClick() {
    this.submit();
  }

  updateActiveState() {
    const count = this.dom.filterEl.querySelectorAll(`.${this.data.activeClass}`).length;

    // When a badge is present display the count
    if (this.dom.badge) {
      this.dom.badge.innerHTML = String(count);

      // Hide when zero
      if (count === 0) {
        this.dom.badge.classList.add('d-none');
      } else {
        this.dom.badge.classList.remove('d-none');
      }
    }

    if (!this.props.multiselectable) {
      const activeItem = this.getActiveItem();

      if (activeItem) {
        this.dom.button.innerText = activeItem.innerText;
      } else {
        this.dom.button.innerText = this.props.originalText;
      }
    }

    // Add the active state to the filter
    if (count > 0) {
      this.dom.filterEl.classList.add(this.data.activeClass);
      return;
    }

    this.dom.filterEl.classList.remove(this.data.activeClass);
  }

  /**
   * @returns {HTMLElement|undefined}
   */
  getActiveItem() {
    return this.dom.items.find((item) => item.classList.contains(this.data.activeClass));
  }

  submit() {
    this.dom.filterEl.dispatchEvent(new Event('change'));
    this.components.dropdown.hide();
  }

  reset() {
    this.dom.items.forEach((item) => item.classList.remove(this.data.activeClass));
  }
}
