import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

/**
 * get regex
 * @param controller
 * @returns {Promise}
 */
export function getViews(controller) {
  return axios.get(`${rootWithLanguage}routes`, {
    params: {
      action: 'views',
      controller,
    },
  });
}

export default {
  getViews,
};
