import { Chart } from 'chart.js/auto';

export default class LineChart {
  /**
   * @param canvas {HTMLCanvasElement}
   * @param data {array}
   */
  constructor(canvas, data) {
    this.dom = {
      canvas,
    };

    this.components = {
      chart: undefined,
    };

    this.props = {
      data,
    };

    this.data = {
      colors: [
        '#2865c3',
        '#191717',
        '#8ab1ef',
        '#94a3c1',
      ],
      defaults: {
        font: {
          family: 'montserrat, helvetica, arial, sans-serif',
          size: 12,
          weight: 500,
        },
        color: '#191717',
        borderColor: '#B0CFE7',
      },
    };

    this.mount();
  }

  mount() {
    if (this.dom.canvas) {
      this.initializeChart();
    }
  }

  initializeChart() {
    Chart.defaults = { ...Chart.defaults, ...this.data.defaults };

    this.components.chart = new Chart(
      this.dom.canvas,
      {
        type: 'line',
        data: {
          labels: this.props.data
            // Get all the dataset keys
            .map((dataset) => dataset.data.map((row) => row.key))
            // Merge to a single array
            .flat()
            // Remove duplicates
            .filter((value, index, array) => array.indexOf(value) === index),
          datasets: this.props.data.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data.map((row) => row.value),
            borderColor: this.data.colors[index],
            backgroundColor: this.data.colors[index],
            borderWidth: 2,
          })),
        },
        options: {
          animation: false,
          layout: {
            padding: 0,
          },
          elements: {
            point: {
              radius: 2.2,
            },
          },
          plugins: {
            legend: {
              labels: {
                color: this.data.defaults.color,
                boxWidth: 10,
                boxHeight: 10,
                padding: 20,
                font: {
                  weight: 500,
                },
              },
              position: 'bottom',
            },
          },
        },
      },
    );
  }
}
