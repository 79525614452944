import { statistics } from '@/js/api/account';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import * as am4charts from '@amcharts/amcharts4/charts';
import { getChartLocale } from '@/js/utils/locales';
import Loader from '@/js/components/loader';

class AccountStatistics {
  constructor() {
    this.dom = {
      chartEl: document.getElementById('chart-container'),
      typeButtons: [...document.querySelectorAll('button[data-type]')],
      loaderEl: document.getElementById('c-stats-loader'),
    };

    this.components = {
      chart: null,
      loader: null,
    };

    this.data = {
      activeClass: 'active',
      chartName: null,
      chartData: null,
      colors: [
        am4core.color('#191717'),
        am4core.color('#1B3B70'),
        am4core.color('#4c6ca4'),
        am4core.color('#84a2d7'),
        am4core.color('#84a2d7'),
        am4core.color('#bfd2f4'),
      ],
    };

    this.events = {
      onTypeButtonClicked: this.onTypeButtonClicked.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.typeButtons.forEach((button) => {
      button.addEventListener('click', this.events.onTypeButtonClicked);
    });

    const activeButton = this.dom.typeButtons.find((button) => button.classList.contains(this.data.activeClass));

    if (this.dom.loaderEl) {
      this.components.loader = new Loader(this.dom.loaderEl);
    }

    this.loadChartData(activeButton.dataset.type);
  }

  loadChartData(type) {
    this.components.loader?.start();

    statistics(type)
      .then((response) => {
        if (response?.data[type]) {
          this.initChart(response?.data[type]);
        }
      })
      .finally(() => {
        this.components.loader?.stop();
      });
  }

  initChart(data) {
    if (!this.dom.chartEl) {
      return;
    }

    am4core.useTheme(am4themesAnimated);
    am4core.useTheme(this.am4themesInvestImmo.bind(this));

    const chart = am4core.create(this.dom.chartEl, am4charts.XYChart);
    chart.data = data;
    chart.dateFormatter.inputDateFormat = 'MMM, yyyy';
    chart.language.locale = getChartLocale();
    // Increase contrast by taking evey second color
    chart.colors.step = 3;

    // Set x/date axis
    const chartDateAxis = chart.xAxes.push(new am4charts.DateAxis());
    chartDateAxis.dateFormats.setKey('day', ' ');
    chartDateAxis.dateFormats.setKey('month', 'MMM');
    chartDateAxis.periodChangeDateFormats.setKey('month', 'MMM');
    chartDateAxis.renderer.line.strokeOpacity = 1;
    chartDateAxis.renderer.line.strokeWidth = 2;
    chartDateAxis.renderer.grid.template.disabled = true;
    chartDateAxis.renderer.grid.template.disabled = true;
    chartDateAxis.renderer.minGridDistance = 80;
    chartDateAxis.cursorTooltipEnabled = false;

    // Set y/value axis
    const chartValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    chartValueAxis.renderer.line.strokeOpacity = 1;
    chartValueAxis.renderer.line.strokeWidth = 2;
    chartValueAxis.renderer.grid.template.disabled = true;
    chartValueAxis.extraMax = 0.01;
    chartValueAxis.cursorTooltipEnabled = false;

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = '[bold {color}]{categoryY}[/]';
    chart.legend.useDefaultMarker = true;
    chart.legend.marginTop = 20;
    chart.legend.marginBottom = 20;

    // Marker
    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.height = 15;
    marker.width = 15;
    marker.align = 'center';
    marker.valign = 'middle';
    marker.cornerRadius(12, 12, 12, 12);

    // Store the chart
    this.components.chart = chart;

    const [serie] = data;

    if (serie) {
      const serieNames = Object.keys(serie).filter((key) => key !== 'date');

      serieNames.forEach((serieName, index) => {
        this.createAxisAndSeries(serieName, index !== (serieNames.length - 1), chartValueAxis);
      });
    }
  }

  /**
   * Create chart series and axis
   * @param serieName {String}
   * @param isDashed {Boolean}
   * @param chartValueAxis
   */
  createAxisAndSeries(serieName, isDashed, chartValueAxis) {
    // Create series
    const series = this.components.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = serieName;
    series.dataFields.dateX = 'date';
    series.tooltipText = '{name} {categoryY}: [bold]{valueY}[/]';
    series.yAxis = chartValueAxis;
    series.name = this.data.chartName;
    series.categoryY = serieName;
    series.strokeWidth = 2;
    series.strokeLinecap = 'round';
    series.minBulletDistance = 15;
    series.sequencedInterpolation = true;
    series.interpolationDuration = 150;
    series.hiddenState.transitionDuration = 400;
    series.defaultState.transitionDuration = 400;
    series.tensionX = 0.85;
    series.fillOpacity = 1;

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [0.04, 0];
    fillModifier.offsets = [0, 0.6];
    fillModifier.gradient.rotation = 90;
    series.segments.template.fillModifier = fillModifier;

    if (isDashed) {
      series.strokeDasharray = 10;
      series.strokeOpacity = 0.35;
    }

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = 'middle';
    series.tooltip.label.textValign = 'middle';
    series.tooltip.animationDuration = 500;

    // Create bullet
    const bullet = series.bullets.push(new am4charts.CircleBullet());
    const interfaceColors = new am4core.InterfaceColorSet();
    bullet.circle.radius = 6;
    bullet.circle.stroke = interfaceColors.getFor('background');
    bullet.circle.strokeWidth = 3;

    // Creating panning cursor
    this.components.chart.cursor = new am4charts.XYCursor();
    this.components.chart.cursor.behavior = 'none';
    this.components.chart.cursor.snapToSeries = series;

    const bulletHover = bullet.states.create('hover');
    bulletHover.properties.scale = 1.5;
  }

  am4themesInvestImmo(target) {
    if (target instanceof am4core.ColorSet) {
      // eslint-disable-next-line no-param-reassign
      target.list = this.data.colors;
    }
  }

  /**
   * @param e {Event}
   */
  onTypeButtonClicked(e) {
    const target = e.currentTarget;
    // Check if the target not already active
    if (target.classList.contains(this.data.activeClass)) {
      return;
    }

    // Dispose the current chart
    if (this.components.chart) {
      this.components.chart.dispose();
    }

    // Get the type
    const { type } = target.dataset;
    // Load the new chart data
    this.loadChartData(type);
    // Remove the active states
    this.dom.typeButtons.forEach((button) => {
      button.classList.remove(this.data.activeClass);
    });
    // Sate the active state
    target.classList.add(this.data.activeClass);
    this.data.chartName = target.textContent;
  }
}

if (document.getElementById('page-account-statistics')) {
  // eslint-disable-next-line no-new
  new AccountStatistics();
}
