import LineChart from '@/js/components/charts/lineChart';

class Estate {
  constructor() {
    this.dom = {
      charts: [...document.querySelectorAll('canvas.chart')],
    };

    this.components = {
      charts: [],
    };

    this.mount();
  }

  mount() {
    this.components.charts = this.dom.charts
      .map((element) => {
        try {
          const data = Object.entries(JSON.parse(element.dataset.dataset))
            .map(([label, values]) => ({
              label,
              data: Object.entries(values).map(([key, value]) => ({
                key,
                value,
              })),
            }));

          return new LineChart(element, data);
        } catch (e) {
          return null;
        }
      })
      .filter((element) => element instanceof LineChart);
  }
}

if (document.getElementById('document-estate-report')) {
  // eslint-disable-next-line no-new
  new Estate();
}
