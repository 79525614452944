/**
 * @param input {HTMLInputElement}
 */
const requireInput = (input) => {
  const inputEl = input;
  inputEl.required = true;

  if (inputEl.placeholder.length !== 0) {
    inputEl.placeholder += ' *';
  }

  if (!inputEl.labels) {
    return;
  }

  inputEl.labels.forEach((label) => {
    const labelEl = label;

    if (labelEl.textContent.length !== 0) {
      labelEl.textContent += ' *';
    }
  });
};

/**
 * @param input {HTMLInputElement}
 */
const unRequireInput = (input) => {
  const inputEl = input;

  inputEl.required = false;

  if (inputEl.placeholder.endsWith('*')) {
    inputEl.placeholder = inputEl.placeholder.slice(0, -1).trim();
  }

  if (!inputEl.labels) {
    return;
  }

  inputEl.labels.forEach((label) => {
    const labelEl = label;

    if (labelEl.textContent.endsWith('*')) {
      labelEl.textContent = labelEl.textContent.slice(0, -1).trim();
    }
  });
};

export {
  requireInput,
  unRequireInput,
};
