import { event } from '@/js/api/tracking';

export default class Tracker {
  /**
   * @param element {HTMLButtonElement}
   * @param data {object}
   */
  constructor({ element, data }) {
    this.dom = {
      element,
    };

    this.props = {
      data,
    };

    this.events = {
      onClick: this.onClick.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.element) {
      this.dom.element.addEventListener('click', this.events.onClick);
    }
  }

  /**
   * @param e {Event}
   */
  async onClick(e) {
    const element = e.currentTarget;

    if (element.offsetParent === null || window.getComputedStyle(element).pointerEvents === 'none') {
      return;
    }

    await event(this.props.data);
  }
}
