import Polygon from '@/js/components/googleMaps/polygon';

export default class DistrictPolygon extends Polygon {
  /**
   * @param options {object}
   * @param data {{nl:string, fr:string}}
   */
  constructor(options, data) {
    super(options);

    this.props = {
      data,
    };
  }

  onClick() {
    super.onClick();

    this.components.polygon.map.fitBounds(this.getBounds());
  }
}
