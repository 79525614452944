/* global google */

import * as Styles from '@/json/map_styles.json';
import DrawingManager from '@/js/components/googleMaps/drawingManager';
import Marker from '@/js/components/googleMaps/marker';
import CityPolygon from '@/js/components/googleMaps/cityPolygon';

export default class GoogleMaps {
  /**
   * @param mapEl {HTMLElement}
   * @param options {{}}
   * @param includeDrawingLibrary {boolean}
   */
  constructor(mapEl, options, includeDrawingLibrary = false) {
    window.onMapLoaded = this.onMapLoaded.bind(this);

    const defaultOptions = {
      center: { lat: 50.91263082619794, lng: 3.647702889873862 }, // Web@vantage coordinates
      zoom: 13,
      styles: Styles,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    };

    this.dom = {
      mapEl,
    };

    this.components = {
      map: null,
    };

    this.data = {
      bounds: null,
      key: 'AIzaSyBX6dRNQ8_5b8rgIheqLMb0mNUAUpyN2Aw',
      callback: 'onMapLoaded',
      options: { ...defaultOptions, ...options },
    };

    this.components = {
      markers: [],
      infoWindows: [],
      polygons: [],
      drawingManager: null,
      geocoder: null,
    };

    this.events = {
      onMouseMove: this.onMouseMove.bind(this),
    };

    this.customEvents = {
      loaded: null,
    };

    if (!window.google) {
      // load map
      this.loadMap(includeDrawingLibrary);
    } else {
      // maps is already loaded
      this.onMapLoaded();
    }
  }

  /**
   * Load google maps scripts
   *
   * @param includeDrawingLibrary {boolean}
   */
  loadMap(includeDrawingLibrary = false) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?&callback=${this.data.callback}&key=${this.data.key}`;

    if (includeDrawingLibrary === true) {
      script.src += '&libraries=drawing';
    }

    document.body.appendChild(script);
  }

  /**
   * Google Maps is loaded
   */
  onMapLoaded() {
    // create instance
    this.components.map = new google.maps.Map(this.dom.mapEl, this.data.options);

    // add bounds
    this.data.bounds = new google.maps.LatLngBounds();

    // Initialize the geocoder
    this.components.geocoder = new google.maps.Geocoder();

    // create event
    this.customEvents.loaded = new CustomEvent('loaded', { detail: this.components.map });

    // dispatch event
    this.dom.mapEl.dispatchEvent(this.customEvents.loaded);
  }

  onMouseMove(e) {
    if (!this.components.drawingManager.dom.tooltipEl) {
      // No tooltip found
      this.dom.mapEl.removeEventListener('mousemove', this.onMouseMove);
    }

    const x = e.clientX;
    const y = e.clientY;
    const offset = 25;

    this.components.drawingManager.dom.tooltipEl.style.top = `${y + offset}px`;
    this.components.drawingManager.dom.tooltipEl.style.left = `${x + offset}px`;

    this.components.drawingManager.showTooltip();
  }

  /**
   * add marker to map
   * @param markerData {Object}
   * @param infoWindowContent {String}
   * @return {Marker}
   */
  addMarker(markerData, infoWindowContent = null) {
    // merge map with options
    const options = { ...markerData, ...{ map: this.components.map } };

    // create maker instance
    const marker = new Marker(options, infoWindowContent);

    // keep marker instance ref
    this.components.markers.push(marker);

    // extend bounds
    this.data.bounds.extend(markerData.position);

    /**
     * if we have more multiple markers, fit map within markers bounds
     * else, use the map its center pos
     */
    if (this.components.markers.length > 1) {
      this.components.map.fitBounds(this.data.bounds);
    }

    return marker;
  }

  /**
   * @param polygon {Polygon}
   * @return {Polygon}
   */
  addPolygon(polygon) {
    // Add the map to the polygon
    polygon.components.polygon.setMap(this.components.map);

    // keep polygon instance ref
    this.components.polygons.push(polygon);

    return polygon;
  }

  removePolygons() {
    this.components.polygons.forEach((polygon) => {
      polygon.reset();
    });

    this.components.polygons = [];
  }

  /**
   * @param cityId typeof int
   * @returns polygon object
   */
  getPolygonById(cityId) {
    return this.components.polygons.find((polygon) => polygon instanceof CityPolygon && polygon.props.cityId === Number(cityId));
  }

  /**
   * @param managerData {object}
   * @param toolTipEl {HTMLElement|null}
   * @return {DrawingManager}
   */
  addDrawingManager(managerData = {}, toolTipEl = null) {
    // merge map with options
    const options = { ...managerData, ...{ map: this.components.map } };

    this.components.drawingManager = new DrawingManager(options, toolTipEl);

    if (toolTipEl) {
      this.dom.mapEl.addEventListener('mousemove', this.events.onMouseMove);
    }

    this.components.drawingManager.components.drawingManager.addListener('circlecomplete', () => {
      if (!this.components.drawingManager.components.circle) {
        return;
      }

      if (this.components.drawingManager.dom.tooltipElCity) {
        // Listen for changes on the circle center
        this.components.drawingManager.components.circle.addListener('center_changed', async () => {
          const { circleCenter } = this.components.drawingManager.data;

          const city = await this.getCityByCoordinates(circleCenter.lat(), circleCenter.lng());

          if (city) {
            this.components.drawingManager.setCircleCity(city);
          }
        });
      }

      if (this.components.drawingManager.dom.tooltipEl) {
        // Show tooltip on current mouse position
        this.dom.mapEl.dispatchEvent(new Event('mousemove'));

        // Trigger circle change event for an initial state
        google.maps.event.trigger(this.components.drawingManager.components.circle, 'center_changed');
        google.maps.event.trigger(this.components.drawingManager.components.circle, 'radius_changed');
      }
    });

    return this.components.drawingManager;
  }

  removeDrawingManager() {
    if (!this.components.drawingManager) {
      return;
    }

    // Clear events
    this.dom.mapEl.removeEventListener('mousemove', this.events.onMouseMove);

    // Reset the drawing manager
    this.components.drawingManager.reset();

    this.components.drawingManager = null;
  }

  /**
   * @param lat {float}
   * @param lng {float}
   * @return Promise<{name:string,postalCode:string,latlng:{lat:float,lng:float}}|undefined>
   */
  getCityByCoordinates(lat, lng) {
    if (!lat || !lng) {
      return new Promise((resolve, reject) => {
        reject(new Error('No valid latitude or longitude given'));
      });
    }

    return this.components.geocoder
      .geocode({ location: { lat, lng, resultType: 'postal_code' } })
      .then((response) => {
        const result = response?.results[0] ?? undefined;

        if (!result) {
          return result;
        }

        const addressComponents = result.address_components ?? [];

        if (addressComponents.length === 0) {
          return undefined;
        }

        // Function to find specified address type
        const findAddressComponent = (requestedType) => addressComponents.find((component) => component.types.filter((type) => type === requestedType).length !== 0);

        const city = findAddressComponent('locality');
        const postalCode = findAddressComponent('postal_code');

        return {
          name: city?.long_name,
          postalCode: postalCode?.long_name,
          latlng: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          },
        };
      });
  }
}
