/* global google */

import { kilometresToMetres, metresToKilometres } from '@/js/utils/conversions';

export default class DrawingManager {
  /**
   * @param options {object}
   * @param tooltipEl {HTMLElement|null}
   */
  constructor(options, tooltipEl = null) {
    const defaultOptions = {
      drawingMode: google.maps.drawing.OverlayType.CIRCLE,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['circle'],
      },
      circleOptions: {
        fillColor: '#7388AB',
        fillOpacity: 0.5,
        strokeWeight: 1,
        clickable: true,
        editable: true,
        draggable: false, // (!) DO NOT SET TO TRUE, this in combination with Geocoding API eats requests
        zIndex: 2,
      },
    };

    this.dom = {
      tooltipEl,
      tooltipElRadius: tooltipEl.querySelector('.radius'),
      tooltipElCityFound: tooltipEl.querySelector('.city-found'),
      tooltipElCity: tooltipEl.querySelector('.city'),
    };

    this.data = {
      options: { ...defaultOptions, ...options },
      drawingCompleted: false,
      circleRadius: null,
      circleCenter: null,
      circleCity: null,
    };

    this.components = {
      drawingManager: null,
      circle: null,
    };

    this.events = {
      onCircleComplete: this.onCircleComplete.bind(this),
      onCircleCenterChanged: this.onCircleCenterChanged.bind(this),
      onCircleRadiusChanged: this.onCircleRadiusChanged.bind(this),
    };

    this.mount();
  }

  mount() {
    this.components.drawingManager = new google.maps.drawing.DrawingManager(this.data.options);

    this.components.drawingManager.addListener('circlecomplete', this.events.onCircleComplete);
  }

  getActiveOverlay(map) {
    // Check if we've all required params to build a circle
    if (!this.data.circleCenter && !this.data.circleRadius) {
      return;
    }

    // Convert floats to Google coordinates
    this.data.circleCenter = new google.maps.LatLng({
      lat: parseFloat(this.data.circleCenter[0]),
      lng: parseFloat(this.data.circleCenter[1]),
    });

    // Add the circle for this city to the map.
    this.components.circle = new google.maps.Circle({
      map,
      strokeWeight: this.data.options.circleOptions.strokeWeight,
      fillColor: this.data.options.circleOptions.fillColor,
      fillOpacity: this.data.options.circleOptions.fillOpacity,
      center: this.data.circleCenter,
      clickable: true,
      editable: true,
      radius: kilometresToMetres(this.data.circleRadius),
    });

    // Disable drawing of new shapes
    this.components.drawingManager.setDrawingMode(null);

    // Trigger overlay complete
    google.maps.event.trigger(this.components.drawingManager, 'circlecomplete', this.components.circle);
  }

  showTooltip() {
    if (this.data.drawingCompleted === false) {
      return;
    }

    if (this.dom.tooltipEl && !this.dom.tooltipEl.classList.contains('is-visible')) {
      this.dom.tooltipEl.classList.add('is-visible');
    }
  }

  /**
   * @param city {{name:string,postalCode:string,latlng:{lat:float,lng:float}}}
   */
  setCircleCity(city) {
    if (!city.postalCode || !city.latlng) {
      this.data.circleCity = null;
      return;
    }

    this.data.circleCity = city;

    if (this.dom.tooltipElCityFound && this.dom.tooltipElCity) {
      this.dom.tooltipElCity.innerHTML = city.name;
      this.dom.tooltipElCityFound.classList.add('is-visible');

      this.dom.tooltipElCityFound.dispatchEvent(new CustomEvent('update'));
    }
  }

  reset() {
    google.maps.event.clearListeners(this.components.drawingManager, 'circlecomplete');

    this.components.drawingManager.setMap(null);

    if (this.components.circle) {
      this.components.circle.setMap(null);
    }

    if (this.dom.tooltipEl) {
      this.dom.tooltipEl.classList.remove('is-visible');

      if (this.dom.tooltipElRadius) {
        this.dom.tooltipElRadius.classList.remove('is-visible');
        this.dom.tooltipElRadius.innerHTML = '';
      }

      if (this.dom.tooltipElCityFound) {
        this.dom.tooltipElCityFound.classList.remove('is-visible');
      }

      if (this.dom.tooltipElCity) {
        this.dom.tooltipElCity.innerHTML = '';
      }
    }
  }

  onCircleComplete(circle) {
    this.components.circle = circle;

    // Disable drawing of new shapes
    this.components.drawingManager.setDrawingMode(null);

    // Tooltip can be shown
    this.data.drawingCompleted = true;

    this.data.circleRadius = metresToKilometres(this.components.circle.getRadius());
    this.data.circleCenter = this.components.circle.getCenter();

    this.components.circle.addListener('center_changed', this.events.onCircleCenterChanged);
    this.components.circle.addListener('radius_changed', this.events.onCircleRadiusChanged);
  }

  onCircleCenterChanged() {
    this.data.circleCenter = this.components.circle.getCenter();

    this.showTooltip();
  }

  onCircleRadiusChanged() {
    this.data.circleRadius = metresToKilometres(this.components.circle.getRadius());

    if (this.dom.tooltipElRadius) {
      this.dom.tooltipElRadius.innerHTML = this.data.circleRadius;
    }

    this.showTooltip();
  }
}
