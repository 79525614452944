import baguetteBox from 'baguettebox.js';
import { Offcanvas } from 'bootstrap';

baguetteBox.run('.gallery', {
  overlayBackgroundColor: '',
  preload: 1,
  // eslint-disable-next-line no-use-before-define
  captions: (element) => {
    if (typeof element === 'undefined') {
      return element;
    }

    return `<button type='button' class='btn btn-primary btn-xs btn-close-lightbox text-white'>${element.dataset.label}</button>`;
  },
  onChange: () => {
    [...document.querySelectorAll('.btn-close-lightbox')]
      .forEach((button) => {
        button.addEventListener('click', () => {
          baguetteBox.hide();
          const offcanvasEl = document.getElementById('offcanvasBrokerContact');
          if (offcanvasEl) {
            Offcanvas.getOrCreateInstance(offcanvasEl)?.show();
          }
        });
      });
  },
  afterShow: () => {
    [...document.querySelectorAll('.btn-close-lightbox')]
      .forEach((button) => {
        button.addEventListener('click', () => {
          baguetteBox.hide();
          const offcanvasEl = document.getElementById('offcanvasBrokerContact');
          if (offcanvasEl) {
            Offcanvas.getOrCreateInstance(offcanvasEl)?.show();
          }
        });
      });
  },
});
