import { requireAll } from '@/js/utils/functions';
import { Tooltip } from 'bootstrap';
import Tablesort from 'tablesort';

// load all js files, but exclude those in partials
requireAll(require.context('./', true, /^(?!.*\/partials\/).*.js$/));

Array.prototype.slice.call(document.querySelectorAll('.toggle-password')).forEach((el) => {
  el.addEventListener('click', (e) => {
    const input = e.currentTarget.parentNode.querySelector('input');
    if (input.type === 'password') {
      input.type = 'text';
    } else {
      input.type = 'password';
    }
  });
});

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

Array.prototype.slice.call(document.querySelectorAll('[data-sortable]')).forEach((el) => {
  Tablesort(el);
});

Array.prototype.slice.call(document.querySelectorAll('.video-modal')).forEach((modal) => {
  modal.addEventListener('hide.bs.modal', () => {
    const iframe = modal.querySelector('iframe');
    const src = iframe.getAttribute('src');

    iframe.src = '';

    setTimeout(() => {
      iframe.src = src;
    }, 300);
  });
});
