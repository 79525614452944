import FormValidation from '@/js/components/forms/formValidation';
import EstateFilterForm from '@/js/components/forms/estateFilterForm';
import ScreenSizes from '@/js/components/screensizes/screensizes';

class Home {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
      formSubscriber: document.getElementById('form-subscriber'),
      advertisementDesktop: document.getElementById('ad-homepage'),
      advertismentMobile: document.getElementById('ad-homepage-top'),
    };

    this.components = {
      filterForm: null,
      subscriberFormValidation: null,
      screenSizes: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.filterForm) {
      this.components.filterForm = new EstateFilterForm(this.dom.filterForm);
      this.components.filterForm.props.submitOnChange = false;
    }

    if (this.dom.formSubscriber) {
      this.components.subscriberFormValidation = new FormValidation(this.dom.formSubscriber);
    }

    this.components.screenSizes = new ScreenSizes();
    if (!this.components.screenSizes.isDesktop()) {
      this.dom.advertismentMobile.appendChild(this.dom.advertisementDesktop);
    }

    if (this.dom.advertisementDesktop) {
      if (this.dom.advertisementDesktop.getBoundingClientRect().height > 0) {
        document.documentElement.style.setProperty('--home-desktop-leaderboard-height', `${Math.round(this.dom.advertisementDesktop.getBoundingClientRect().height * 0.5)}px`);
      } else {
        document.documentElement.style.setProperty('--home-desktop-leaderboard-height', '0px');
      }
    }

    if (this.dom.advertismentMobile) {
      if (this.dom.advertismentMobile.getBoundingClientRect().height > 0) {
        document.documentElement.style.setProperty('--home-mobile-leaderboard-height', `${Math.round(this.dom.advertismentMobile.getBoundingClientRect().height)}px`);
      } else {
        document.documentElement.style.setProperty('--home-mobile-leaderboard-height', '0px');
      }
    }
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
