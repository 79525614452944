import FormValidation from '@/js/components/forms/formValidation';
import { requireInput, unRequireInput } from '@/js/utils/form';
import FormValidationFieldset from '@/js/components/forms/formValidationFieldset';
import ToggleEstateActive from '@/js/components/buttons/toggleEstateActive';

export default class EstateFormValidation extends FormValidation {
  /**
   * @param formEl {HTMLFormElement}
   */
  constructor(formEl) {
    super(formEl, false);

    this.actions = {
      save: 'save',
      validate: 'validate',
    };

    this.currentAction = this.actions.validate;

    this.dom = {
      ...this.dom,
      activeToggleCheckbox: document.getElementById('estate-active-toggle'),
      locationTypeSelect: document.getElementById('estate-location-property-info'),
      referenceInput: document.querySelector('input[name="Price[ExternalUID]"]'),
    };

    this.components = {
      ...this.components,
      fieldsets: [...this.dom.formEl.querySelectorAll('fieldset[name]')]
        .map((fieldset) => new FormValidationFieldset(fieldset)),
      toggleEstateActive: null,
    };

    this.data = {
      ...this.data,
      resetFormOnSuccess: false,
    };

    this.data.message.scroll = false;

    this.mount();
  }

  mount() {
    super.mount();

    if (this.dom.activeToggleCheckbox) {
      this.components.toggleEstateActive = new ToggleEstateActive({ element: this.dom.activeToggleCheckbox });
      this.dom.activeToggleCheckbox.addEventListener('change', this.onActiveToggleChange.bind(this));
    }

    if (this.dom.locationTypeSelect) {
      this.dom.locationTypeSelect.addEventListener('change', this.onLocationTypeSelectChange.bind(this));
    }

    if (this.dom.referenceInput) {
      this.dom.referenceInput.addEventListener('change', this.onReferenceInputChange.bind(this));
    }
  }

  onActiveToggleChange() {
    this.currentAction = this.actions.validate;

    const existingInput = this.dom.formEl.querySelector('input[name="action"]');

    if (existingInput) {
      // action already in place
      existingInput.value = 'validate';
    } else {
      // add input action
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'action';
      input.value = this.currentAction;
      this.dom.formEl.appendChild(input);
    }

    // Submit form
    this.submit(this.dom.activeToggleCheckbox);
  }

  onLocationTypeSelectChange() {
    const value = Number(this.dom.locationTypeSelect.value);

    [...document.querySelectorAll('input[name="Location[Street]"], input[name="Location[Number]"]')]
      .forEach((input) => {
        if (value === 2) {
          unRequireInput(input);
          return;
        }

        requireInput(input);
      });
  }

  onReferenceInputChange() {
    [...document.querySelectorAll('[data-display-reference]')]
      .forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.textContent = this.dom.referenceInput.value;
      });
  }

  onRequestSuccess(response) {
    super.onRequestSuccess(response);

    this.handleFieldsetErrors();

    if (this.currentAction === this.actions.validate && this.components.toggleEstateActive) {
      this.components.toggleEstateActive.onRequestSuccess(response);
    }

    // Set to default state
    this.currentAction = this.actions.save;
  }

  onRequestError(error) {
    super.onRequestError(error);

    if (this.currentAction === this.actions.validate && this.components.toggleEstateActive) {
      this.components.toggleEstateActive.onRequestError(error);
    }

    // Set to default state
    this.currentAction = this.actions.save;
  }

  parseMessage(messageObj) {
    super.parseMessage(messageObj);
    // Don't set alert class
    this.data.message.class = null;
  }

  setMessageText(text) {
    this.dom.message.querySelector('strong').textContent = text;
  }

  handleFieldsetErrors() {
    // Get the name of the groups from the errors
    const errorGroups = Object.keys(this.data.errors)
      .map((key) => {
        const index = key.indexOf('[');

        if (index === -1) {
          return key;
        }

        return key.slice(0, index);
      })
      // Make the list unique
      .filter((item, index, self) => index === self.indexOf(item));

    // Set the valid state
    this.components.fieldsets.forEach((fieldset) => {
      const { name } = fieldset.dom.fieldsetEl;

      if (errorGroups.includes(name)) {
        const message = this.data.errors[name] ?? null; // Get a general message for the fieldset
        fieldset.setInvalid(message);
      } else {
        fieldset.setValid();
      }
    });
  }

  /**
   * @param submitter {HTMLElement|null}
   */
  submit(submitter = null) {
    this.dom.formEl.dispatchEvent(new SubmitEvent('submit', { submitter, cancelable: true }));
  }
}
