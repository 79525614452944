import Polygon from '@/js/components/googleMaps/polygon';

export default class CityPolygon extends Polygon {
  /**
   * @param options {object}
   * @param cityId {Number}
   *
   * eslint-disable no-useless-constructor
   */
  constructor(options, cityId) {
    super(options);

    /**
     * @type {{cityId: Number}}
     */
    this.props = {
      cityId,
    };
  }

  onClick() {
    super.onClick();

    const node = document.getElementById(this.props.cityId);

    if (!node) {
      this.setActive();
      return;
    }

    this.setInactive();
  }
}
