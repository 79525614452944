export default class InputFilter {
  /**
   * @param input {HTMLInputElement}
   */
  constructor(input) {
    this.dom = {
      input,
    };

    this.events = {
      onInputChange: undefined,
    };

    this.mount();
  }

  mount() {
    if (this.dom.input) {
      this.dom.input.addEventListener('change', this.events.onInputChange);
    }
  }

  reset() {
    this.dom.input.value = '';
  }
}
