import EstateFilterForm from '@/js/components/forms/estateFilterForm';
import { Tooltip } from 'bootstrap';

/* eslint-enable */

class EstateOverview {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
      estateList: document.getElementById('estates-overview-list'),
      sortSelect: document.getElementById('sort'),
      advertismentMobile: document.getElementById('ad-homepage-top'),
    };

    this.components = {
      filterForm: undefined,
    };

    this.mount();
  }

  mount() {
    if (this.dom.filterForm) {
      this.components.filterForm = new EstateFilterForm(this.dom.filterForm);

      if (this.dom.sortSelect) {
        this.dom.sortSelect.addEventListener('change', this.onSortSelectChange.bind(this));
      }
      if (this.dom.estateList) {
        this.dom.estateList.addEventListener('update', () => {
          const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
          tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
        });
      }
    }

    // When the URL contains a # with the property ID we scroll to this property when it exists
    if (window.location.hash) {
      const cleanHash = window.location.hash.substring(1);
      const node = document.querySelector(`[data-id="${cleanHash}"]`);
      if (node) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onSortSelectChange() {
    this.components.filterForm.submit(this.dom.sortSelect);
  }
}

if (document.getElementById('page-estates-overview')) {
  // eslint-disable-next-line no-new
  new EstateOverview();
}
