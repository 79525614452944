import ActionElement from '@/js/components/forms/actionElement';
import { Modal } from 'bootstrap';

export default class ToggleEstateActive extends ActionElement {
  /**
   * @param element {HTMLInputElement}
   * @param url {string|null}
   * @param action {string|null}
   */
  constructor({ element, url = null, action = null }) {
    super({ element, url, action });
  }

  mount() {
    super.mount();

    this.dom = {
      ...this.dom,
      activateErrorModal: document.getElementById('activate-error-modal'),
      maxExceededErrorModal: document.getElementById('max-exceeded-error-modal'),
    };
  }

  onElementClick(e) {
    // Set value based on checked state
    this.dom.element.value = this.dom.element.checked ? 1 : 0;

    // Set the estate url on the modal link
    if (this.dom.activateErrorModal) {
      const estateUrl = e.currentTarget.dataset.requestUrl;

      this.dom.activateErrorModal.querySelectorAll('.js-estate-link').forEach((link) => {
        // eslint-disable-next-line no-param-reassign
        link.href = estateUrl;
      });
    }

    return super.onElementClick();
  }

  onRequestSuccess(response) {
    super.onRequestSuccess(response);

    // Set active state
    this.dom.element.checked = response.data?.values?.Active === true;

    const { type, text } = response.data?.message ?? {};

    if (type === 'error' && text) {
      this.showErrorModal(text);
    }
  }

  onRequestError(error) {
    super.onRequestError(error);

    // Set active state
    this.dom.element.checked = error.response?.data?.Active === true;
  }

  showErrorModal(error) {
    if (error === 'max-exceed' && this.dom.maxExceededErrorModal) {
      const modal = Modal.getOrCreateInstance(this.dom.maxExceededErrorModal);
      modal.show();
      return;
    }

    if (!this.dom.activateErrorModal) {
      return;
    }

    const modal = Modal.getOrCreateInstance(this.dom.activateErrorModal);
    modal.show();
  }
}
