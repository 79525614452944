import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

export function statistics(type) {
  return axios.get(`${rootWithLanguage}statistics/${type}`);
}

export default {
  statistics,
};
