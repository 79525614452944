export default class ActionResponseData {
  /**
   * @param props {Object}
   */
  constructor(props) {
    this.props = {
      url: props.url || null,
      redirect: props.redirect || null,
      fragments: props.fragments || {},
    };
  }

  process() {
    if (this.props.url) {
      window.history.replaceState('', '', this.props.url);
    }

    if (this.props.redirect) {
      window.location.replace(this.props.redirect);
    }

    this.renderFragments();
  }

  renderFragments() {
    Object.entries(this.props.fragments).forEach(([selector, fragment]) => {
      if (!(typeof fragment === 'string' || fragment instanceof String)) {
        return;
      }

      const dom = new DOMParser().parseFromString(fragment, 'text/html');
      const node = dom.querySelector(selector);

      if (!node) {
        return;
      }

      [...document.querySelectorAll(selector)]
        .forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          element.innerHTML = node.innerHTML;
          element.dispatchEvent(new Event('update'));
        });
    });
  }
}
