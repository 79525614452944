import ActionElement from '@/js/components/forms/actionElement';

class AccountFavorites {
  constructor() {
    this.dom = {
      estateList: null,
    };

    this.components = {
      removeFavoriteButtons: [],
    };

    this.mount();
  }

  mount() {
    this.dom.estateList = document.getElementById('account-favorites-estates-list');

    if (this.dom.estateList) {
      this.dom.estateList.addEventListener('update', this.mount.bind(this)); // Execute mount because html is replaced
    }

    // Init remove buttons
    this.components.removeEstateButtons = [...document.querySelectorAll('button[data-request-action="remove-favorite"]')]
      .map((button) => new ActionElement({ element: button }));
  }
}

if (document.getElementById('page-account-favorites')) {
  // eslint-disable-next-line no-new
  new AccountFavorites();
}
