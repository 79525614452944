/* global google */
import Awesomplete from 'awesomplete';
import GoogleMaps from '@/js/components/googleMaps/map';
import DistrictPolygon from '@/js/components/googleMaps/districtPolygon';
import CityPolygon from '@/js/components/googleMaps/cityPolygon';
import { Modal } from 'bootstrap';
import { getMapData } from '@/js/api/estates';

export default class MapOverlay {
	/**
	 * @param cities {array}
	 * @param citySearch {EstateCitySearch}
	 */
	constructor(cities, citySearch) {
    this.mapData.meta = cities

		/**
     *
     * @type {{citySearch: EstateCitySearch|undefined}}
     */
    this.components = {
      citySearch: undefined,
    };

		this.data = {
			isDistrictZoom: false,
			zoomLevelCity: 10,
		};

    this.modes = {
      select: 'MODE_SELECT',
      circle: 'MODE_CIRCLE',
    };

    this.currentMode = this.modes.select;

    this.states = {
      city: 'STATE_CITY',
      district: 'STATE_DISTRICT',
    };

    this.currentState = this.states.city;

		window.mapOverlay = this;

		this.setCitySearch(citySearch);
		this.mount();
	}

	/**
	 * @type {GoogleMaps|null}
	 */
	map = null;
	clickedMode = null;
	listTemplate = null;
	mapEl = document.getElementById('map-overlay');
	rootEl = document.getElementById('l-map-overlay');
	listCities = document.getElementById('list-cities');
	locationFromMapWrapper = document.querySelector('.location-from-map-wrapper');
	formEl = this.locationFromMapWrapper.closest('form');
	locationFromMap = document.querySelector('.c-location-from-map');

	// Data
	mapData = {};
	activeCities = [];
	activeDistricts = [];
	circleRadius = null;
	circleCenter = null;
	circleCity = null;
	circleCityId = null;
	// Flatten cities array
	lang = this.rootEl.dataset.language;
	defaultLang = 'nl';
	autocompleteData = null;

	/**
	 * @type {DrawingManager|null}
	 */
	drawingManager = null;
	circle = null;
	isOverlayDrawn = false;
	tooltipEl = document.getElementById('overlay-tt');
	fnOverlayComplete = null;

	/**
	 * Initialize data
	 */
	mount() {
		// Overwrite autoSubmit value
		// var autoSubmitEl = document.querySelector('.l-search-form[data-autosubmit]');
		// if (autoSubmitEl) {
		// 	mapOverlay.autoSubmit = (autoSubmitEl.getAttribute('data-autosubmit') === "true");
		// }

		this.autocompleteData = this.components.citySearch.props.cities;
    // this.initTagify();
    this.initAutocomplete();
   	this.setInitialValues();
    this.initEvents();
	};

	/**
	 * Toggle map overlay
	 *
	 * @param countryCode {string}
	 */
	async initOverlay(countryCode) {
		this.mapData = {
			...this.mapData,
			...await getMapData(countryCode).then((response) =>
				Object.entries(response.data ?? {})
					.reduce((result, [_, value]) => {
						const [key] = Object.keys(value);

						if (key !== undefined) {
							result[key] = value[key];
						}

						return result;
					}, {})),
		};

		this.listTemplate = this.setListItemTemplate(this.listCities);
		this.createMap();

		this.rootEl.addEventListener('selectionCleared', () => {
			if (this.clickedMode) {
				this.setMode(this.clickedMode);
			} else {
				this.setMode(this.currentMode);
			}
		});
	};

	initEvents() {
		var btnClear = document.getElementById('btn-clear');
		var btnConfirm = document.getElementById('btn-confirm');
		var btnCloseOverlay = document.querySelectorAll('.btn-close-overlay');
		var btnClearConfirmed = document.getElementById('btn-clear-selection');
		var btnSelectionRemove = this.locationFromMap?.querySelector('.btn-selection-remove');

		if (btnSelectionRemove) {
			btnSelectionRemove.addEventListener('click',  () => {
				this.setMode(this.modes.select, false);
        this.clearMap();
        this.submitForm();
			});
		}

		if (btnClear) {
			btnClear.addEventListener('click', this.showModalConfirm.bind(this));
		}

		if (btnConfirm) {
			btnConfirm.addEventListener('click', this.confirmMap.bind(this));
		}

		Array.prototype.slice.call(btnCloseOverlay).forEach((btn) => {
			btn.addEventListener('click', this.submitForm.bind(this));
		});

		if (btnClearConfirmed) {
			btnClearConfirmed.addEventListener('click', this.clearConfirmed.bind(this));
		}

		// Add click handler to switch mode buttons
    [...this.rootEl.querySelectorAll('[data-mode]')].forEach((btn) => {
      btn.addEventListener('click', this.btnModeClick.bind(this));
    });

		// Don't submit on enter
		document.getElementById('ac-location').addEventListener('keydown', function (e) {
			if (e.key === 'Enter') {
				e.preventDefault();
			}
		});
	};

	initAutocomplete() {
		[...document.querySelectorAll('[data-location-autocomplete]')].forEach((input) => {
			// http://leaverou.github.io/awesomplete/#customization
			new Awesomplete(input, {
				list: mapOverlay.autocompleteData,
				minChars: 2,
				maxItems: 100,
				// filter: Awesomplete.FILTER_STARTSWITH,
				replace() {
					return this.input.value = '';
				},
				sort: function (a, b) {
          return mapOverlay.sortByBestMatch(a, b, input.value);
        },
				data: (item) => ({ label: item.value, value: item.id }),
				filter: (text, input) => {
					// Filter out suffix
					if (text.includes(this.components.citySearch.data.parentCitySuffix)) {
						text = text.substr(0, text.indexOf(this.components.citySearch.data.parentCitySuffix));
					}

					// Equal to filter contains
					return RegExp(input.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i').test(text);
				},
				item: (text, input, item_id, test) => {
					var html = input.trim() === '' ? text : text.replace(RegExp((input.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')), 'gi'), '<mark>$&</mark>');

					if (html.includes(this.components.citySearch.data.parentCitySuffix)) {
						html = html.replace(RegExp((this.components.citySearch.data.parentCitySuffix.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')), 'gi'), '<small class="text-gray-400">$&</small>');
					}

					var element = document.createElement('li');
					var o = {
						innerHTML: html,
						'role': 'option',
						'aria-selected': 'false',
						'id': 'awesomplete_list_' + text.length + '_item_' + item_id,
					};

					for (var i in o) {
						var val = o[i];

						if (i === 'inside') {
							$(val).appendChild(element);
						} else if (i === 'around') {
							var ref = $(val);
							ref.parentNode.insertBefore(element, ref);
							element.appendChild(ref);

							if (ref.getAttribute('autofocus') != null) {
								ref.focus();
							}
						} else if (i in element) {
							element[i] = val;
						} else {
							element.setAttribute(i, val);
						}
					}

					return element;
				},
			});

			input.addEventListener('awesomplete-select', (e) => {
				const id = Number(e.text.value);

				// find city by id
				const city = this.autocompleteData.find((data) => id === Number(data.id));

				// check if is district
				const isDistrict = city.id === city.di && e.text.includes(this.components.citySearch.data.parentCitySuffix);

				// populate activeCities / activeDistricts
				this.addFromAutocomplete(city, isDistrict);
			});
		});
	};

  /**
   * sort by best match
   * @param a {string}
   * @param b {string}
   * @param searchedValue {string}
   * @returns {number|number}
   */
  sortByBestMatch = function (a, b, searchedValue) {
    var key = searchedValue.toLowerCase();
    var isMatchA = a.toLowerCase().startsWith(key);
    var isMatchB = b.toLowerCase().startsWith(key);

    if (isMatchA !== isMatchB) { // XOR
      return isMatchA ? -1 : 1;
    }

    return a.localeCompare(b);
  };

	/**
	 * Add autocomplete values to activeCities & activeDistricts
	 * @param city {object}
	 * @param isDistrict {boolean}
	 */
	addFromAutocomplete(city, isDistrict) {
		const el = document.getElementById('i-' + city.id);

		if (el) {
			// District is already active, set city checked state
			el.checked = true;
		} else if (!isDistrict) {
			this.setMode(this.modes.select);
		}

		if (isDistrict) {
			// Add to active district
			this.addActiveDistricts([city.di]);
			this.addCitiesFromDistricts([city.di]);
			return;
		}

		// Add to active city
		this.addActiveCities([city.id]);
	};

	/**
	 * remove autocomplete values to activeCities & activeDistricts
	 * @param city {object}
	 * @param isParentCity {boolean}
	 */
	removeFromAutocomplete(city, isParentCity) {
		const cityListItem = document.getElementById('i-' + city.id);

		if (cityListItem) {
			// city is already active, set city checked state
			cityListItem.checked = false;
		} else if (!isParentCity) {
			this.setMode(mapOverlay.modes.select);
		}

		if (isParentCity) {
      // Remove from active districts
      this.removeActiveDistrict(Number(city.di));
      this.removeActiveCity(Number(city.di));

      // Deactivate the polygon when able to
      if (window.google) {
        const item = cityListItem.closest('.city-list-item');
        const polygon = mapOverlay.map.getPolygonById(Number(item.id))
        if (polygon) {
          // Set polygon state to inactive
          polygon.setStateInactive();
        }
      }
		} else {
			// Add to active city
			this.removeActiveCity(city.id);

      if (window.google) {
        // set polygon id
        this.setPolygonState(cityListItem);
      }
		}

		if (this.activeCities.length < 1) {
			this.toggleActionButtons(false);
		}
	};

	setInitialValues() {
		if (!this.components.citySearch) {
			return;
		}

		const activeCities = mapOverlay.components.citySearch?.getActiveCities() ?? [];

		if (activeCities.length !== 0) {
			this.addActiveCities(activeCities.map((city) => city.id));

			// mapOverlay.setSubmitValues();
			// mapOverlay.modifyTagsNoSubmit();
			// mapOverlay.setTagifyLastTagValues();
		}

		const { radius, coordinates } = this.components.citySearch.data;

		if (radius && coordinates) {
			this.circleCityId = this.activeCities.slice(0, 1);

			this.currentMode = this.modes.circle;
			this.showCircleInput();

			return;
		}
	};

	// Get a clone of out list item template and remove dummy node
	setListItemTemplate = function (list) {
		const node = list.querySelector('li.d-none');

		if (!node) {
			return;
		}

		const clone = node.cloneNode(true);

		list.removeChild(node);
		clone.classList.remove('d-none');
		return clone;
	};

	/**
	 * Create Google Maps
	 */
	createMap() {
		if (!this.mapEl) {
			return;
		}

		let center = null;
		switch (this.rootEl.dataset.country) {
			case 'NL':
				center = { lat: 52.371807, lng: 4.896029 };
				break;
			case 'FR':
				center = { lat: 48.864716, lng: 2.349014 };
				break;
			case 'LU':
				center = { lat: 49.611622, lng: 6.131935 };
				break;
			default:
				center = { lat: 50.8465573, lng: 4.351697 };
				break;
		}

		this.map = new GoogleMaps(
			this.mapEl,
	{
				center: center,
				zoom: 10,
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
			},
true,
		);

		this.map.dom.mapEl.addEventListener('loaded', this.propertiesMapCallback.bind(this))
	};

	/**
	 * Callback function when map is loaded
	 */
	propertiesMapCallback() {
		// this.map.init();

		// We have to define it here since we don't have a reference to google in the createMap fn
		this.map.components.map.setOptions({
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_CENTER
			},
		});

		// No mode has been initialized - use default mode
		this.setMode(this.currentMode, true);
	};

  /**
   * @param e {Event}
   */
	btnModeClick(e) {
		this.clickedMode = e.currentTarget.dataset.mode;
		this.showModalConfirm();
	};

	setMode(mode, noModeInitialized) {
		// Check if mode has changed
		if (mode !== this.currentMode || noModeInitialized) {
			// Check if we need cleaning
			if (!noModeInitialized) {
				this.resetModeCircle();
				this.resetModeSelect();
			}

			// Initialize active mode
			switch (mode) {
				case this.modes.circle:
					this.currentMode = mapOverlay.modes.circle;
					this.initModeCircle();
					break;

				default:
					this.currentMode = mapOverlay.modes.select;
					this.initModeSelect();
					break;
			}

			this.removeButtonActiveState();

			// Add active state
			const btn = this.rootEl.querySelector('[data-mode=' + this.currentMode + ']');
			if (btn) {
				btn.classList.add('active');
			}
		}
	};

	initModeSelect() {
		if (this.map.components.map) {
			this.map.components.map.addListener('bounds_changed', this.mapBoundsChanged.bind(this));
			this.map.components.map.addListener('zoom_changed', this.mapZoomChanged.bind(this));

			google.maps.event.trigger(this.map.components.map, 'zoom_changed', { vertex: 0 });

			this.buildList();
			this.rootEl.classList.remove('no-sidebar');
		}

		this.showTags();
	};

	initModeCircle() {
		// Initialize Google Drawing Manager
		this.drawingManager = this.map.addDrawingManager({}, this.tooltipEl);

		this.drawingManager.components.drawingManager.addListener('circlecomplete', () => {
			this.toggleActionButtons(true);
		});

		if (this.drawingManager.dom.tooltipElCityFound) {
			this.drawingManager.dom.tooltipElCityFound.addEventListener('update', () => {
				// Postal code exact match
				let result = this.mapData.meta.find((city) => city.pc === this.drawingManager.data.circleCity.postalCode);

				if (!result) {
					// Cannot match ZIP
					// Find the closest coordinates in our data
					result = this.mapData.meta.reduce((prev, curr) => {
						if (Math.abs(curr.cn[1] - location.lat) < Math.abs(prev.cn[1] - location.lat) &&
							Math.abs(curr.cn[0] - location.lng) < Math.abs(prev.cn[0] - location.lng)) {
							return curr;
						}

						return prev;
					});
				}

				if (!result) {
					return;
				}

				this.circleCityId = result.id;

				// In Circle view only 1 is selectable do remove all others
				this.activeCities = [];
				this.activeDistricts = [];

				// Set active city
				this.addActiveCities([this.circleCityId]);
			});
		}

		const [cityId] = this.activeCities;
		const { radius, coordinates } = this.components.citySearch.data;

		if (cityId && radius && coordinates) {
			this.drawingManager.data.circleRadius = radius;
			this.drawingManager.data.circleCenter = [coordinates.lat, coordinates.lng];

			const city = this.getCityById(cityId);

			this.drawingManager.setCircleCity({
				name: city[this.lang],
				postalCode: city.pc,
				latlng: {
					lat: this.components.citySearch.data.coordinates.lat,
					lng: this.components.citySearch.data.coordinates.lng,
				},
			});
		}

		this.drawingManager.getActiveOverlay(this.map.components.map);

		// Hide sidebar
    this.rootEl.classList.add('no-sidebar');

    this.showCircleInput();
	};

	mapZoomChanged() {
    if (this.currentMode === this.modes.circle) {
      return;
    }

		if (this.map.components.map.getZoom() < this.data.zoomLevelCity && this.mapData.districts) {
			if (this.currentState) {
				// Switch state from city to district
				if (this.currentState === this.states.city) {
					this.map.removePolygons();
					this.setDistrictPolygons();
				}
			} else {
				// No initial state set and zoom level is smaller than the city zoom level, load district polygons
				this.setDistrictPolygons();
			}

			// Set active state to district
			this.currentState = this.states.district;

			return;
		}

		if (this.currentState === this.states.district) {
				// Switch state from district to city
				console.log('Switch state from district to city');
				this.map.removePolygons();
				this.setCityPolygons();

				// Set active state to city
				this.currentState = this.states.city;

				return;
		}

		// No initial state set and zoom level is equal or greater than the city zoom level, load city polygons
		this.setCityPolygons();

		// Set active state to city
		this.currentState = this.states.city;
	};

	mapBoundsChanged() {
		// Set minimum zoom level when clicked on a district
		if (this.map.components.map.getZoom() < this.data.zoomLevelCity && this.data.isDistrictZoom) {
			this.data.isDistrictZoom = false;
			this.map.components.map.setZoom(this.data.zoomLevelCity);
		}
	};

	setDistrictPolygons () {
		if (!this.mapData.districts) {
			return;
		}

		this.mapData.districts.forEach((district) => {
			const coordinates = this.getPath(district.pg);
			const data = { 'nl': district.nl, 'fr': district.fr };

			// Create polygon, data is passed for future reference
			const polygon = this.map.addPolygon(new DistrictPolygon({ path: coordinates }, data));

			// Attach event listeners
			polygon.components.polygon.addListener('click', () => {
				// Enforce minimum zoom level for cities
				if (this.currentState === this.states.district) {
					this.data.isDistrictZoom = true;
				}
			});
		});
	};

	setCityPolygons() {
		if (!this.mapData.cities) {
			return;
		}

		this.mapData.cities.forEach((city) => {
			if (!city.pg) {
				console.warn(`City with ID: ${city.id} has no coordinates`);
				return;
			}

			const coordinates = this.getPath(city.pg);

			// Create polygon, data is passed for future reference
			const polygon = this.map.addPolygon(new CityPolygon({ path: coordinates }, Number(city.id)));

			// Attach event listeners
			polygon.components.polygon.addListener('click', () => {
				const cityId = polygon.props.cityId;

				// Check if parent city is active
				if (this.activeDistricts.indexOf(cityId) !== -1) {
					this.removeActiveDistrict(cityId);
					return;
				}

				// Update active cities and districts
				this.addActiveDistricts([this.getDistrictById(cityId).di]);
				this.addCitiesFromDistricts([this.getDistrictById(cityId).di]);
			});
		});

		this.setActivePolygons();
	};

	setActivePolygons() {
		this.activeCities.forEach((id) => {
			const input = document.getElementById('i-' + id);

			if (!input) {
				return;
			}

			input.checked = true;

			if (window.google) {
				this.setPolygonState(input);
			}
		});
	};

	getPath = function (path) {
		var gmArray = new google.maps.MVCArray();
		path.forEach(function (coordinates) {
			gmArray.push(new google.maps.LatLng(coordinates[1], coordinates[0]));
		});
		return gmArray;
	};

	attachInputChanged = function (input) {
		if (input) {
			input.addEventListener('change', mapOverlay.inputChangeHandler);
		}
	};

	inputChangeHandler = function (e) {
		var input = e.target;
		var cityId = mapOverlay.cleanInputId(input.id);

		if (input.checked) {
			// Add to active cities
			mapOverlay.addActiveCities([cityId]);
		} else {
			// Remove from active cities
			mapOverlay.removeActiveCity(cityId);
		}

		mapOverlay.setPolygonState(input);
	};

	setPolygonState = function (input) {
		var cityListItem = input.closest('.city-list-item');
		var list = input.closest('ul');
		var checkedInputs = list.querySelectorAll('input:checked');
		var polygon = mapOverlay.map.getPolygonById(Number(cityListItem.id));

		if (!polygon) {
			return;
		}

		if (checkedInputs.length === 0) {
			// None are checked
			var removeButton = cityListItem.querySelector('.btn-remove-city');
			if (removeButton) {
				// Remove from active districts
				mapOverlay.removeActiveDistrict(Number(cityListItem.id));

				// Set polygon state to inactive
				polygon.setStateInactive();

				// Remove from list
				removeButton.click();
			}
		} else {
			if (list.children.length - checkedInputs.length !== 0) {
				// Some are checked
				polygon.setStateHover();
			} else {
				// All are checked
				polygon.setStateActive();
			}

			polygon.detachMouseEvents();
		}
	};

	attachRemoveButton(button, polygonId) {
		if (!button) {
			return;
		}

		button.setAttribute('data-target', polygonId);
		button.addEventListener('click', this.removeButtonClick.bind(this));
	};

	removeButtonClick(e) {
		const id = e.currentTarget.getAttribute('data-target');
		const cityNode = document.getElementById(id);

		if (cityNode) {
			const  polygon = this.map.getPolygonById(id);

			if (polygon) {
				google.maps.event.trigger(polygon.components.polygon, 'click', { vertex: 0 }); // Third param is required
			} else {
				// Remove from a different state than city (polygon will be empty)
				this.removeActiveDistrict(Number(cityNode.id));
			}
		}

		// Hide confirm / erase buttons
		if (this.listCities.children.length === 0) {
			this.toggleActionButtons(false);
		}
	};

	/**
	 * Add districts to active districts
	 * @param districtIds typeof array
	 */
	addActiveDistricts = function (districtIds) {
		// Concat array of district id's
		mapOverlay.activeDistricts = mapOverlay.activeDistricts.concat(districtIds.map((id) => Number(id)));

		// Ensure unique id's
		mapOverlay.activeDistricts = mapOverlay.activeDistricts.filter((item, index, self) => index === self.indexOf(item));
	};

	/**
	 * Add cities to active cities
	 * @param cityIds typeof array
	 */
	addActiveCities = function (cityIds) {
		cityIds = cityIds.map((id) => Number(id));

		// Concat array of city id's
		mapOverlay.activeCities = mapOverlay.activeCities.concat(cityIds);

		// Ensure unique id's
		mapOverlay.activeCities = mapOverlay.activeCities.filter(function (item, index, self) {
			return index === self.indexOf(item);
		});

		// Make sure city district is active
		cityIds.forEach(function (cityId) {
			const city = mapOverlay.getCityById(cityId);

			if (mapOverlay.activeDistricts.indexOf(city.di) < 0) {
				mapOverlay.addActiveDistricts([Number(city.di)]);
			}
		});

		// Build list
		mapOverlay.buildList();

		// Count active cities
		mapOverlay.setAmountOfSelectedCities();

		// Show confirm / erase buttons
		if (mapOverlay.listCities.children.length > 0) {
			mapOverlay.toggleActionButtons(true);
		} else {
			mapOverlay.toggleActionButtons(false);
		}
	};

	/**
	 * Add all cities of a active district to the active cities
	 * @param districtIds
	 */
	addCitiesFromDistricts = function (districtIds) {
		districtIds = districtIds.map((id) => Number(id));

		districtIds.forEach(function (districtId) {
				// Make sure the district exists in active districts array
				if (mapOverlay.activeDistricts.indexOf(districtId) < 0) {
					throw new Error('District with id: ' + districtId + ' is not active.');
				}

				mapOverlay.addActiveCities(mapOverlay.mapData.meta
					// Get cities
					.filter(function (city) {
						return Number(city.di) === districtId;
					})
					// Flatten array of cities to only id's
					.map(function (city) {
						return city.id;
					}));
			});
	};

	/**
	 * Remove city from active cities
	 * @param cityId
	 */
	removeActiveCity = function (cityId) {
		mapOverlay.activeCities = mapOverlay.activeCities.filter(i => i !== Number(cityId));

		// Count active cities
		mapOverlay.setAmountOfSelectedCities();
	};

	/**
	 * Remove district from active districts
	 * @param districtId
	 */
	removeActiveDistrict = function (districtId) {
		// Remove parent city form the active list
		mapOverlay.activeDistricts = mapOverlay.activeDistricts.filter(i => i !== districtId);

		mapOverlay.components.citySearch.removeCity(districtId, true);

		// Remove all cities from the district
		mapOverlay.mapData.meta.forEach(function (city) {
			if (Number(city.di) === Number(districtId)) {
				mapOverlay.removeActiveCity(city.id);
			}
		});

		const listItem = document.querySelector(`li[id="${districtId}"]`)

		// Remove node
		if (listItem) {
			listItem.parentNode.removeChild(listItem);
		}
	};

	/**
	 * @param cityId {integer}
	 * @returns {{id:string,di:string,nl:string,fr:string,pc:string,cn:array<string>}|undefined}
	 */
	getCityById = function (cityId) {
		cityId = Number(cityId);
		return mapOverlay.mapData.meta.find((city) => Number(city.id) === cityId);
	};

	/**
	 * @param districtId {Number}
	 * @returns obj or undefined
	 */
	getDistrictById = function (districtId) {
		return mapOverlay.mapData.meta.find(city => Number(city.di) === districtId);
	};

	/**
	 * @param districtId
	 * @returns obj or undefined
	 */
	getCitiesFromDistrict = function (districtId) {
		return mapOverlay.mapData.meta.filter(function (o) {
			return o.di === districtId;
		});
	};

	getCityByCoordinates = function (lat, lng, cb) {
		if (lat && lng) {
			var resultType = '&result_type=postal_code';
			var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + resultType + '&key=' + mapOverlay.map.data.key;

			var xhr = new XMLHttpRequest();
			var method = 'GET';

			xhr.open(method, url, true);
			xhr.onreadystatechange = function () {
				// Request is done
				if (xhr.readyState === 4) {
					// Status is ok
					if (xhr.status === 200) {
						var results = JSON.parse(xhr.responseText).results;
						// Check if we have a location
						if (results.length > 0) {
							var result = results[0].formatted_address;
							result = result.substring(0, result.indexOf(','));
							cb(result, results[0].geometry.location);
						} else {
							console.log('Could not determine location');
						}
					} else {
						console.error('Request failed: ' + xhr.statusText);
					}
				}
			};

			xhr.send();
		}
	};

	/**
	 * Remove i- prefix
	 * @param id
	 * @returns {*}
	 */
	cleanInputId = function (id) {
		return id.slice(2, id.length);
	};

	buildList() {
		if (!this.map) {
			// Map is not initialized, don't build the list
			return;
		}

		var cityListItems = document.querySelectorAll('.city-list-item');
		var newDistricts = mapOverlay.activeDistricts;

		// Check active cities from existing districts
		mapOverlay.setActivePolygons();

		// Ignore already existing districts
		Array.prototype.slice.call(cityListItems).forEach(li => {
			newDistricts = newDistricts.filter(id => id !== Number(li.id));
		});

		// Loop through new districts
		newDistricts.forEach(function (id) {
			var node = mapOverlay.listTemplate.cloneNode(true);
			var innerList = node.querySelector('ul');
			var innerListItemDummy = innerList.querySelector('li');
			var innerListItem = innerListItemDummy.cloneNode(true);
			var city = mapOverlay.getCityById(id);
			var cityName = city[mapOverlay.lang] ? city[mapOverlay.lang] : city[mapOverlay.defaultLang];

			// Remove dummy node
			innerList.removeChild(innerListItemDummy);

			// Set city list item id to district id
			node.id = id;

			// Set ZIP and city name
			node.querySelector('.main-city').innerHTML = city.pc + ' ' + cityName;

			// Attach remove button functionality
			mapOverlay.attachRemoveButton(node.querySelector('.btn-remove-city'), city.di);

			// Add cities to district
			mapOverlay.mapData.meta.forEach(function (o) {
				if (o.di === city.di) {
					var li = innerListItem.cloneNode(true);
					var input = li.querySelector('input');
					var label = li.querySelector('label');
					var prefix = 'i-';

					// Set label values
					label.setAttribute('for', prefix + o.id);
					label.innerHTML = o[mapOverlay.lang] ? o[mapOverlay.lang] : o[mapOverlay.defaultLang];
					label.innerHTML += ' (' + o.pc + ')';

					// Set input values
					input.id = prefix + o.id; // Must be unique to prevent duplicate ID's with list items
					input.value = o.id;
					input.name = o.id + '[]';

					// Check if the city exists in our active cities array
					if (mapOverlay.activeCities.indexOf(Number(o.id)) === -1) {
						input.checked = false;
					}

					// Attach change handler
					mapOverlay.attachInputChanged(input);

					// Append to (inner) list
					innerList.appendChild(li);
				}
			});

			/**
			 * Set polygon state
			 * Must be outside of creating nodes list because we only need to check
			 * once all the cities of a district are added
			 */
			Array.prototype.slice.call(innerList.querySelectorAll('input')).forEach(function (input) {
				mapOverlay.setPolygonState(input);
			});

			// Append to (outer) list
			mapOverlay.listCities.appendChild(node);
		});
	};

	setCity = function (city, location) {
		var pc = city.slice(0, 4);
		mapOverlay.circleCity = city;

		try {
			// ZIP exact match
			mapOverlay.circleCityId = mapOverlay.mapData.meta.find(function (city) {
				return city.pc === pc;
			}).id;
		} catch (err) {
			// Cannot match ZIP
			// Find closest coordinates in our data
			mapOverlay.circleCityId = mapOverlay.mapData.meta.reduce(function (prev, curr) {
				if (Math.abs(curr.cn[1] - location.lat) < Math.abs(prev.cn[1] - location.lat) &&
					Math.abs(curr.cn[0] - location.lng) < Math.abs(prev.cn[0] - location.lng)) {
					return curr;
				} else {
					return prev;
				}
			}).id;
		}

		// In Circle view only 1 is selectable do remove all others
		mapOverlay.activeCities = [];
		mapOverlay.activeDistricts = [];

		// Set active city
		mapOverlay.addActiveCities([mapOverlay.circleCityId]);

		if (mapOverlay.tooltipElCityFound && mapOverlay.tooltipElCity) {
			mapOverlay.tooltipElCity.innerHTML = mapOverlay.circleCity;
			mapOverlay.tooltipElCityFound.classList.add('is-visible');
		}
	};

	setAmountOfSelectedCities = function () {
		document.getElementById('selected-amount').innerHTML = mapOverlay.activeCities.length;

		// if (!mapOverlay.mobileConfirm) {
		return false;
		// }

		// if (mapOverlay.activeCities.length > 0) {
		// 	mapOverlay.mobileConfirm.classList.add('is-visible');
		// } else {
		// 	mapOverlay.mobileConfirm.classList.remove('is-visible');
		// }
	};

	// Show or hide the confirm or erase buttons
	toggleActionButtons = function (show) {
		var btnOverlayActions = mapOverlay.rootEl.querySelectorAll('.btn-overlay-action');
		if (btnOverlayActions.length > 0) {
			Array.prototype.slice.call(btnOverlayActions).forEach(function (btn) {
				if (show) {
					btn.classList.add('is-visible');
				} else {
					btn.classList.remove('is-visible');
				}
			});
		}
	};

	removeButtonActiveState = function () {
		var activeBtnModes = mapOverlay.rootEl.querySelectorAll('[data-mode].active');

		if (activeBtnModes.length > 0) {
			Array.prototype.slice.call(activeBtnModes).forEach(function (btn) {
				btn.classList.remove('active');
			});
		}
	};

	showModalConfirm() {
		if (!(this.activeCities.length > 0 || this.isOverlayDrawn)) {
			this.clearConfirmed();
			return;
		}

		const modal = new Modal(document.getElementById('map-modal-confirm'));

		if (modal) {
			modal.show();
		}
	};

	// Confirmed in modal to remove current selection
	clearConfirmed() {
		this.clearMap();

		this.rootEl.dispatchEvent(new CustomEvent('selectionCleared'));
	};

	clearMap() {
		// Hide confirm / erase buttons
		this.toggleActionButtons(false);

    this.activeCities = [];
    this.activeDistricts = [];

		this.clearData();

		// Clear text input in case it contained text
		Array.prototype.slice.call(document.querySelectorAll('[data-location-autocomplete]'))
			.forEach(function (input) {
				input.value = '';
			});

		if (this.map) {
			switch (this.currentMode) {
				case this.modes.select:
					this.resetModeSelect();
					this.initModeSelect();
					break;

				case this.modes.circle:
					this.resetModeCircle();
					this.initModeCircle();
					break;

				default:
					break;
			}
		}

		// If deleted circle mode, switch back to select mode
		if (!this.map && !this.circleRadius && !this.circleCenter) {
			this.currentMode = this.modes.select;
		}

		// Set amount of selected cities to 0
		this.setAmountOfSelectedCities();
	};

	confirmMap() {
		// Close overlay
		if (this.rootEl.classList.contains('is-visible')) {
      this.rootEl.querySelector('[data-toggle-overlay="l-map-overlay"]').click();
			return;
		}

		this.submitForm();
	};

	// show tags textarea, hide circle input
	showTags = function () {
		var tagify = mapOverlay.formEl.querySelector('.js-city-tags.tagify');

		if (mapOverlay.locationFromMap) {
			mapOverlay.locationFromMap.classList.add('d-none');
		}

		tagify.classList.remove('d-none');
	};

	// show circle input, hide tags textarea
	showCircleInput = function () {
		var tagify = mapOverlay.formEl.querySelector('.js-city-tags.tagify');
		tagify.classList.add('d-none');

		if (mapOverlay.locationFromMap) {
			mapOverlay.locationFromMap.classList.remove('d-none');
		}
	};

	submitForm() {
		// Remove previous tags
		this.components.citySearch.reset();

		switch (this.currentMode) {
			case this.modes.select:
				this.showTags();
				this.confirmSelect();
				break;

			case this.modes.circle:
				this.showCircleInput();
				this.confirmCircle();
				break;

			default:
				break;
		}
	};

	confirmSelect() {
    this.components.citySearch.addCitiesGroupedByParent(this.activeCities);
		this.components.citySearch.submit();
	};

	confirmCircle() {
		const { circleCity, circleRadius, circleCenter } = this.drawingManager.data;

		if (!circleCity || !circleRadius || !circleCenter) {
			this.components.citySearch.removeCircleCity();
      this.setMode(this.modes.select);
		}
		else {
			this.components.citySearch.setCircleCity(this.circleCityId, circleCity.name, circleRadius, { lat: circleCenter.lat(), lng: circleCenter.lng() });
		}

		this.components.citySearch.submit();
	};

	resetModeSelect() {
		if (window.google) {
			google.maps.event.clearListeners('bounds_changed', this.map.components.map);
			google.maps.event.clearListeners('zoom_changed', this.map.components.map);

			// Remove all polygons
			this.map.removePolygons();

			// Remove all list items, events are garbage collected
			while (this.listCities.firstChild) {
        this.listCities.removeChild(mapOverlay.listCities.firstChild);
			}
		}

		// Reset vars
		this.activeCities = [];
    this.activeDistricts = [];
    this.currentState = this.states.city;
	};

	resetModeCircle() {
		if (this.map) {
			this.map.removeDrawingManager();
		}

		this.circleCityId = null;
		this.components.citySearch.dom.inputRadius.value = '';
		this.components.citySearch.dom.inputCoordinates.value = '';
	};

	clearData() {
		this.activeCities = [];
		this.activeDistricts = [];
		this.circleRadius = null;
		this.circleCenter = null;
		this.circleCity = null;
		this.circleCityId = null;
	};

	/**
	 * @param citySearch {EstateCitySearch}
	 */
	setCitySearch(citySearch) {
		this.components.citySearch = citySearch;

		// Add remove handler
		this.components.citySearch.components.tagify.on('remove', (e) => {
			const { id, isDistrict } = e.detail?.data ?? {}

			// find city by id
			const city = mapOverlay.mapData.meta.find(data => id === Number(data.id));

			// remove from activeCities / activeDistricts
			this.removeFromAutocomplete(city, isDistrict);
		});

    // Add on add handler
    this.components.citySearch.components.tagify.on('add', (e) => {
      const { id, isDistrict } = e.detail?.data ?? {}
      if(isDistrict){
        this.addActiveDistricts([id])
        this.addCitiesFromDistricts([id]);
      } else {
        this.addActiveCities([id]);
      }
    });
	}
};
