export default class Loader {
  constructor(element) {
    this.dom = {
      element,
    };

    this.data = {
      activeClass: 'is-visible',
    };
  }

  start() {
    if (!this.isActive()) {
      this.dom.element.classList.add(this.data.activeClass);
    }
  }

  stop() {
    if (this.isActive()) {
      this.dom.element.classList.remove(this.data.activeClass);
    }
  }

  isActive() {
    return this.dom.element.classList.contains(this.data.activeClass);
  }
}
