import Tagify from '@yaireo/tagify/dist/tagify';
import { lang } from '@/js/utils/locales';

export default class EstateCitySearch {
  /**
   * @param filterForm {FilterForm}
   * @param cities {array}
   */
  constructor(filterForm, cities) {
    this.dom = {
      cityInput: filterForm.dom.formEl.querySelector('.js-city-tags'),
      inputRadius: filterForm.dom.formEl.querySelector('input[name="Filters[Radius]"]'),
      inputCoordinates: filterForm.dom.formEl.querySelector('input[name="Filters[Coordinates]"]'),
      btnCircleCity: filterForm.dom.formEl.querySelector('.btn-selection'),
    };

    this.components = {
      filterForm,
      tagify: null,
    };

    this.props = {
      lang,
      cities,
      submit: false,
    };

    /**
     * @type {{parentCityTranslations:{nl: string, en: string, fr: string}, parentCitySuffix: string|null, coordinates: {lat: float,lng: float}|null}, radius: Number|null}}
     */
    this.data = {
      parentCityTranslations: { nl: 'deelgemeenten', en: 'sub-municipalities', fr: 'arrondissements' },
      parentCitySuffix: null,
      radius: null,
      coordinates: null,
      tagifySubmitTimeout: null,
    };

    this.states = {
      idle: 'STATE_IDLE',
      busy: 'STATE_BUSY',
    };

    this.currentState = this.states.idle;

    this.mount();
  }

  mount() {
    this.formatCities();

    if (this.dom.cityInput) {
      this.initTagify();

      this.dom.cityInput.addEventListener('change', (e) => {
        if (this.currentState === this.states.idle) {
          clearTimeout(this.data.tagifySubmitTimeout);
          this.data.tagifySubmitTimeout = setTimeout(() => {
            this.submit(e.currentTarget);
          }, 1200);
        }
      });
    }

    if (this.dom.inputRadius) {
      this.dom.inputRadius.addEventListener('change', (e) => {
        this.submit(e.currentTarget);
      });
    }

    if (this.dom.inputCoordinates) {
      this.dom.inputCoordinates.addEventListener('change', (e) => {
        this.submit(e.currentTarget);
      });
    }

    this.setInitialValues();

    // Tagify use a timeout, so we must anticipate on this
    setTimeout(() => {
      // Make submitting available
      this.currentState = this.states.idle;
    }, 100);
  }

  initTagify() {
    this.components.tagify = new Tagify(this.dom.cityInput, {
      enforceWhitelist: true,
      delimiters: null,
      whitelist: this.props.cities,
      dropdown: {
        placeAbove: false,
        maxItems: 30,
        // @TODO compare algorithm should be shared with map overlay js
        sortby: (results, searchValue) => {
          // map values (array of strings)
          const mappedValues = results.map((result) => result.value);

          // sort values (strings)
          let sortedValues = mappedValues.sort((a, b) => {
            const key = searchValue.toLowerCase();
            const isMatchA = a.toLowerCase().startsWith(key);
            const isMatchB = b.toLowerCase().startsWith(key);

            if (isMatchA !== isMatchB) { // XOR
              return isMatchA ? -1 : 1;
            }

            return a.localeCompare(b);
          });

          // re-map sorted values (string) to results (tagify object)
          sortedValues = sortedValues.map((value) => results.find((result) => result.value === value));

          // return array tagify objects
          return sortedValues;
        },
      },
      originalInputValueFormat: (values) => values
        .map((item) => {
          if (item.isDistrict === false) {
            return item;
          }

          // If parent is selected return all children
          return this.getSubCities(item.di);
        })
        // Array flatten
        .flat()
        // Get the id's
        .map((item) => item.id)
        .join(','),
    });
  }

  setInitialValues() {
    const cityInputValue = this.dom.cityInput?.dataset.value;

    if (!cityInputValue) {
      return;
    }

    // Parse the value
    const cityIds = cityInputValue.split(',')
      .map((id) => Number(id))
      .filter((id) => id !== 0);

    if (cityIds.length === 0) {
      return;
    }

    // Prevent the form submits on initial values
    this.currentState = this.states.busy;

    // Get the radius
    this.data.radius = Number(this.dom.inputRadius?.value ?? 0);
    // Get the coordinates
    const coordinates = this.dom.inputCoordinates?.value ?? '';
    if (coordinates !== '') {
      const [lat, lng] = coordinates.split(',');
      this.data.coordinates = lat && lng ? { lat: parseFloat(lat), lng: parseFloat(lng) } : null;
    }

    // When radius and coordinates are given, process one city
    if (this.data.radius && this.data.coordinates) {
      const [cityId] = cityIds;
      const city = this.getCity(cityId);

      if (city) {
        this.setCircleCity(cityId, city.name, this.data.radius, this.data.coordinates);
      }

      return;
    }

    // Set the cities in select mode
    this.addCitiesGroupedByParent(cityIds);
  }

  /**
   * @param ids {array<integer>}
   * @param isParentCity {boolean}
   */
  addCities(ids, isParentCity = false) {
    const values = ids
      .map((id) => this.getCity(id, isParentCity))
      .filter((item) => !!item);

    this.components.tagify.addTags(values);
  }

  addCitiesGroupedByParent(ids) {
    // Set the cities in select mode
    [...Object.entries(Object.groupBy(ids, (id) => this.getCity(id).di))]
      .forEach(([parentCityId, subCityIds]) => {
        // If all sub cities are given, use the parent city as value
        if (subCityIds.length > 1 && this.getSubCities(Number(parentCityId)).length === subCityIds.length) {
          this.addCities([Number(parentCityId)], true);
        } else {
          this.addCities(subCityIds);
        }
      });
  }

  removeCity(id, isParentCity = false) {
    const city = this.getCity(id, isParentCity);

    if (city) {
      this.components.tagify.removeTags(city.value);
    }
  }

  /**
   * @param cityId {Number}
   * @param cityName {string}
   * @param radius {Number}
   * @param latlng {{lat:float,lng:float}}
   */
  setCircleCity(cityId, cityName, radius, latlng) {
    this.dom.inputRadius.value = radius;
    this.dom.inputCoordinates.value = `${latlng.lat},${latlng.lng}`;

    if (this.dom.btnCircleCity) {
      let valueStr = cityName;

      if ('circleText' in this.dom.btnCircleCity.dataset) {
        valueStr += ` (+ ${this.dom.btnCircleCity.dataset.circleText}: ${radius}km)`;
      }

      this.dom.btnCircleCity.innerHTML = valueStr;
    }

    this.addCities([Number(cityId)]);
  }

  removeCircleCity() {
    this.dom.inputRadius.value = '';
    this.dom.inputCoordinates.value = '';

    if (this.dom.btnCircleCity) {
      this.dom.btnCircleCity.innerHTML = '';
    }
  }

  getActiveCities() {
    return this.components.tagify.value
      .map((item) => {
        if (item.isDistrict === false) {
          return item;
        }

        // If parent is selected return all children
        return this.getSubCities(item.di);
      })
      // Array flatten
      .flat();
  }

  /**
   * @param id {Number}
   * @param isParentCity
   * @return {{id:Number,di:Number,value:string,isDistrict:boolean}}
   */
  getCity(id, isParentCity = false) {
    return this.props.cities.find((item) => item.id === id && item.isDistrict === isParentCity);
  }

  /**
   * @param parentCityId {Number}
   * @return {array<{id:Number,di:Number,value:string,isDistrict:boolean}>}
   */
  getSubCities(parentCityId) {
    return this.props.cities.filter((city) => city.di === parentCityId && city.isDistrict === false);
  }

  /**
   * @param submitter {HTMLElement}
   */
  submit(submitter = null) {
    if (this.currentState === this.states.idle) {
      // Prevent new submits
      this.currentState = this.states.busy;

      // Tagify use a timeout, so we must anticipate on this
      setTimeout(() => {
        // Submit the form
        this.components.filterForm.submit(submitter);

        // Make submitting available
        this.currentState = this.states.idle;
      }, 100);
    }
  }

  reset() {
    this.components.tagify.removeAllTags();
  }

  formatCities() {
    this.data.parentCitySuffix = ` (+ ${this.data.parentCityTranslations[this.props.lang]})`;

    this.props.cities = this.props.cities
      .map((city) => {
        const name = city[lang] ?? city[this.data.lang];
        const output = [];

        const subCities = this.props.cities.filter((item) => item.di === city.di);

        // Check if city has sub-cities
        if (city.id === city.di && subCities.length > 1) {
          output.push({
            name: `${name} ${this.data.parentCitySuffix}`,
            value: `${name} (${city.pc})${this.data.parentCitySuffix}`,
            id: Number(city.id),
            di: Number(city.di),
            isDistrict: true,
          });
        }

        // Add city
        output.push({
          name,
          value: `${name} (${city.pc})`,
          id: Number(city.id),
          di: Number(city.di),
          isDistrict: false,
        });

        return output;
      })
      .flat();
  }
}
