import axios from 'axios';
import { root } from '@/js/utils/paths';

export function getMapData(countryCode) {
  return axios.get(`${root}data/json/${countryCode}-mapData.json`);
}

export function getMeta(countryCode) {
  return axios.get(`${root}data/json/${countryCode}-meta.json`);
}

export function getPrices() {
  return axios.get(`${root}data/json/prices.json`);
}

export default {
  getMapData,
  getMeta,
  getPrices,
};
