import Tagify from '@yaireo/tagify';
import { getPrices } from '@/js/api/estates';

export default class EstatePriceFilter {
  /**
   * @param filterForm {FilterForm}
   */
  constructor(filterForm) {
    this.dom = {
      priceTags: [...filterForm.dom.formEl.querySelectorAll('.js-price-tags')],
    };

    this.components = {
      filterForm,
      tagifies: [],
    };

    this.mount();
  }

  async mount() {
    const prices = await getPrices().then((response) => response.data ?? []);

    this.components.tagifies = this.dom.priceTags.map((priceTag) => new Tagify(priceTag, {
      enforceWhitelist: false,
      mode: 'select',
      whitelist: ['--', ...prices],
      originalInputValueFormat: (values) => values
        .map((item) => item?.value)
        .filter((value) => value !== null)
        .map((value) => Number(value.replace(/[^\d\s]/gi, '')))
        .toString(),
    }));
  }

  reset() {
    this.components.tagifies.forEach((tagify) => tagify.removeAllTags());
  }
}
