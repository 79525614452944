import ActionHandler from '@/js/components/forms/actionHandler';

export default class ActionElement extends ActionHandler {
  /**
   * @param element {HTMLElement}
   * @param url {string|null}
   * @param action {string|null}
   */
  constructor({ element, url = null, action = null }) {
    super();

    this.dom = {
      element,
      loaderEl: document.getElementById('c-loader'),
    };

    this.props = {
      url: url ?? element.dataset.requestUrl,
      action: action ?? element.dataset.requestAction,
    };

    this.events = {
      onElementClick: this.onElementClick.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.element) {
      this.dom.element.addEventListener('click', this.events.onElementClick);
    }

    if (this.dom.loaderEl) {
      this.addLoader(this.dom.loaderEl);
    }
  }

  onElementClick() {
    if (!this.props.url) {
      return;
    }

    const formData = new FormData();

    Object.keys(this.dom.element.dataset)
      .forEach((key) => {
        let name = key.replace('request', ''); // Remove request from attribute name
        name = name.charAt(0).toLowerCase() + name.slice(1);

        // Skip the url attribute
        if (name === 'url') {
          return;
        }

        // add the attribute to the form data
        formData.append(name, this.dom.element.dataset[key]);
      });

    if (this.dom.element.hasAttribute('name') && this.dom.element.hasAttribute('value')) {
      formData.append(this.dom.element.name, this.dom.element.value);
    }

    this.sendFormData({
      url: this.props.url,
      formData,
      action: this.props.action,
    });
  }

  onRequestSuccess(response) {
    super.onRequestSuccess(response);
    this.dom.element.dispatchEvent(new CustomEvent('success', {
      detail: response.data,
    }));
  }

  onRequestError(error) {
    super.onRequestError(error);

    this.dom.element.dispatchEvent(new CustomEvent('error', {
      detail: error.response.data,
    }));
  }
}
