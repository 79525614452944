/* global google */

export default class Polygon {
  /**
   * create Google Maps polygon
   * @param options {Object}
   */
  constructor(options) {
    const styling = {
      fillOpacity: {
        active: 0.4,
        inactive: 0,
        hover: 0.25,
      },
      strokeWeight: {
        active: 2,
        inactive: 1,
        hover: 1,
      },
    };

    // https://developers.google.com/maps/documentation/javascript/reference/polygon#PolylineOptions
    const defaultOptions = {
      strokeColor: '#162f5a',
      strokeOpacity: 1,
      strokeWeight: styling.strokeWeight.inactive,
      fillColor: '#7388AB',
      fillOpacity: styling.fillOpacity.inactive,
      zIndex: 2,
    };

    this.components = {
      polygon: null,
    };

    this.data = {
      options: { ...defaultOptions, ...options },
      styling,
    };

    this.states = {
      active: 'STATE_ACTIVE',
      inactive: 'STATE_INACTIVE',
      hover: 'STATE_HOVER',
    };

    this.currentState = this.states.inactive;

    this.events = {
      onClick: this.onClick.bind(this),
      onMouseOver: this.onMouseOver.bind(this),
      onMouseOut: this.onMouseOut.bind(this),
    };

    this.mount();
  }

  mount() {
    this.components.polygon = new google.maps.Polygon(this.data.options);

    this.components.polygon.addListener('click', this.events.onClick);
    this.attachMouseEvents();
  }

  onClick() {
    return this;
  }

  /**
   * Polygon mouse over
   */
  onMouseOver() {
    this.setStateHover();
  }

  /**
   * Polygon mouse out
   */
  onMouseOut() {
    this.setStateInactive();
  }

  setStateHover() {
    this.currentState = this.states.hover;

    this.components.polygon.setOptions({ fillOpacity: this.data.styling.fillOpacity.hover });
  }

  setStateActive() {
    this.currentState = this.states.active;

    this.components.polygon.setOptions({ fillOpacity: this.data.styling.fillOpacity.active });
    this.components.polygon.setOptions({ strokeWeight: this.data.styling.strokeWeight.active });
  }

  setStateInactive() {
    this.currentState = this.states.inactive;

    this.components.polygon.setOptions({ fillOpacity: this.data.styling.fillOpacity.inactive });
    this.components.polygon.setOptions({ strokeWeight: this.data.styling.strokeWeight.inactive });
  }

  setActive() {
    this.setStateActive();
    this.detachMouseEvents();
  }

  setInactive() {
    this.setStateInactive();
    this.attachMouseEvents();
  }

  attachMouseEvents() {
    this.components.polygon.addListener('mouseover', this.events.onMouseOver);
    this.components.polygon.addListener('mouseout', this.events.onMouseOut);
  }

  detachMouseEvents() {
    google.maps.event.clearListeners(this.components.polygon, 'mouseover');
    google.maps.event.clearListeners(this.components.polygon, 'mouseout');
  }

  getBounds() {
    const bounds = new google.maps.LatLngBounds();
    const paths = this.components.polygon.getPaths();
    let path;

    for (let i = 0; i < paths.getLength(); i += 1) {
      path = paths.getAt(i);

      for (let j = 0; j < path.getLength(); j += 1) {
        bounds.extend(path.getAt(j));
      }
    }

    return bounds;
  }

  /**
   * @return {boolean}
   */
  isActive() {
    return this.currentState === this.states.active;
  }

  reset() {
    // Remove from map
    this.components.polygon.setMap(null);

    // Detach event listeners
    google.maps.event.clearListeners(this.components.polygon, 'click');
    google.maps.event.clearListeners(this.components.polygon, 'mouseover');
    google.maps.event.clearListeners(this.components.polygon, 'mouseout');
  }
}
