import FilterForm from '@/js/components/forms/filterForm';
import EstateCitySearch from '@/js/components/filters/estateCitySearch';
import EstatePriceFilter from '@/js/components/filters/estatePriceFilter';
import { getMeta } from '@/js/api/estates';
/* eslint-disable */
import MapOverlay from '@/js/components/mapOverlay';
/* eslint-enable */

export default class EstateFilterForm extends FilterForm {
  async mount() {
    super.mount();

    this.dom = {
      ...this.dom,
      mapOverlayEl: document.getElementById('l-map-overlay'),
    };

    this.data = {
      ...this.data,
      countryCode: this.dom.mapOverlayEl?.dataset?.country ?? null,
    };

    if (!this.data.countryCode) {
      console.error('No country was found on the map overlay element');
      return;
    }

    const cities = await this.fetchCities();

    this.components = {
      ...this.components,
      citySearch: new EstateCitySearch(this, cities),
      priceFilter: new EstatePriceFilter(this),
    };

    if (this.dom.mapOverlayEl) {
      this.components.mapOverlay = new MapOverlay(cities, this.components.citySearch);

      [...document.querySelectorAll('button[data-toggle-overlay]')]
        .forEach((btn) => {
          btn.addEventListener('click', async () => {
            if (this.dom.mapOverlayEl.classList.contains('is-visible')) {
              this.dom.mapOverlayEl.classList.remove('is-visible');
              return;
            }

            this.dom.mapOverlayEl.classList.add('is-visible');

            if (!this.components.mapOverlay.map) {
              await this.components.mapOverlay.initOverlay(this.data.countryCode);
            }
          });
        });
    }
  }

  sendFormData({ url, formData, action = null }) {
    // Split cities into multi array post
    [...formData.entries()].forEach(([key, item]) => {
      if (key !== 'Filters[Cities]') {
        return;
      }

      item.split(',').forEach((value) => {
        if (value !== '') {
          formData.append('Filters[Cities][]', value);
        }
      });

      formData.delete('Filters[Cities]');
    });

    return super.sendFormData({ url, formData, action });
  }

  reset() {
    super.reset();
    this.components.citySearch.reset();
    this.components.priceFilter.reset();
  }

  /**
   * @return {Promise}
   */
  fetchCities() {
    return getMeta(this.data.countryCode)
      .then((response) => response.data?.[0]?.meta ?? []);
  }
}
