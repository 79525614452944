import ActionElement from '@/js/components/forms/actionElement';
import ToggleEstateActive from '@/js/components/buttons/toggleEstateActive';

class AccountEstateOverview {
  constructor() {
    this.dom = {
      activeErrorModal: document.getElementById('activate-error-modal'),
      maxExceededErrorModal: document.getElementById('max-exceeded-error-modal'),
    };

    this.components = {
      activeToggleCheckboxes: [],
      removeEstateButtons: [],
    };

    this.mount();
  }

  mount() {
    // Init active toggle checkboxes
    this.components.activeToggleCheckboxes = [...document.querySelectorAll('input[data-request-action="activate-estate"]')]
      .map((checkbox) => new ToggleEstateActive({ element: checkbox }));

    // Init remove buttons
    this.components.removeEstateButtons = [...document.querySelectorAll('button[data-request-action="remove-estate"]')]
      .map((button) => new ActionElement({ element: button }));

    this.components.removeEstateButtons.forEach((button) => {
      button.dom.element.addEventListener('success', (e) => {
        const trElement = e.currentTarget.closest('tr');

        if (trElement) {
          trElement.remove();
        }
      });
    });
  }
}

if (document.getElementById('page-account-estate-overview')) {
  // eslint-disable-next-line no-new
  new AccountEstateOverview();
}
