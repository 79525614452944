export default class ScreenSizes {
  constructor() {
    this.data = {
      mqlDesktop: window.matchMedia('(min-width: 992px)'),
    };
  }

  isDesktop() {
    return this.data.mqlDesktop.matches;
  }
}
