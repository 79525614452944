import Am4langNlNL from '@amcharts/amcharts4/lang/nl_NL';
import Am4langFrFR from '@amcharts/amcharts4/lang/fr_FR';
import Am4langEnUS from '@amcharts/amcharts4/lang/en_US';

export function getLanguage() {
  return document.documentElement.lang;
}

// alias for getLanguage()
export const lang = getLanguage();

export function getChartLocale() {
  switch (lang) {
    case 'fr':
      return Am4langFrFR;
    case 'en':
      return Am4langEnUS;
    default:
      return Am4langNlNL;
  }
}

export default {
  lang,
  getChartLocale,
};
