import DropdownFilter from '@/js/components/filters/dropdownFilter';
import ActionHandler from '@/js/components/forms/actionHandler';

class BrokerDetail {
  constructor() {
    this.dom = {
      sortSelect: document.getElementById('sort'),
    };

    this.components = {
      sortDropdown: null,
    };

    this.events = {
      onSortChange: this.onSortChange.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.sortSelect) {
      this.components.sortDropdown = new DropdownFilter(this.dom.sortSelect);
      this.components.sortDropdown.dom.filterEl.addEventListener('change', this.events.onSortChange);
    }
  }

  onSortChange() {
    const activeItem = this.components.sortDropdown.getActiveItem();

    if (!activeItem) {
      return;
    }

    if (!('value' in activeItem.dataset)) {
      return;
    }

    const { url, name, action } = this.dom.sortSelect.dataset;

    if (!url || !name || !action) {
      return;
    }

    const formData = new FormData();
    formData.append(name, activeItem.dataset.value);

    (new ActionHandler()).sendFormData({ url, formData, action });
  }
}

if (document.getElementById('page-brokers-detail')) {
  // eslint-disable-next-line no-new
  new BrokerDetail();
}
